import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";

const NavBar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <nav className="bg-gray-200 shadow-inner">
        <div className="container px-4 h-10">
          <ul className="flex space-x-4 py-2">
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={toggleDropdown}
                className="text-black focus:outline-none underline text-sm"
              >
                Master
              </button>
              {dropdownOpen && (
                <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50 text-justify">
                  <Link
                    to="/Register"
                    className="block px-4 py-1 text-gray-800 hover:bg-gray-200 text-xs"
                  >
                    Registration
                  </Link>
                  <Link
                    to="/Client"
                    className="block px-4 py-1 text-gray-800 hover:bg-gray-200 text-xs"
                  >
                    <span></span>Client<span className="pl-16">F1</span>
                  </Link>
                  <Link
                    to="/Match"
                    className="block px-4 py-1 text-gray-800 hover:bg-gray-200 text-xs"
                  >
                    Match<span className="pl-16">F2</span>
                  </Link>
                  <Link
                    to="/Session"
                    className="block px-4 py-1 text-gray-800 hover:bg-gray-200 text-xs"
                  >
                    Session<span className="pl-14">F3</span>
                  </Link>
                  <Link
                    to="/Client"
                    className="block px-4 py-1 text-gray-800 hover:bg-gray-200 text-xs"
                  >
                    Online Client
                  </Link>
                  <Link
                    to="/SystemUser"
                    className="block px-4 py-1 text-gray-800 hover:bg-gray-200 text-xs"
                  >
                    System User
                  </Link>
                  <Link
                    to=""
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200 text-xs"
                  >
                    Exit
                  </Link>
                </div>
              )}
            </div>

            <li>
           
              <Link
                to="/SessionTransaction"
                className="text-gray-800 hover:text-blue-600 underline text-sm"
              >
               Transaction
              </Link>
              { /*<div className="relative" ref={dropdownRef}>
                <button
                  onClick={toggleDropdown}
                  className="text-black focus:outline-none underline text-sm"
                >
                  Transaction
                </button>
                
            {dropdownOpen && (
                  <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50 text-justify">
                    <Link
                      to="/MatchTransaction"
                      className="block px-4 py-1 text-gray-800 hover:bg-gray-200 text-xs"
                    >
                      Match<span className="pl-16">F4</span>
                    </Link>

                    <Link
                      to="/SessionTransaction"
                      className="block px-4 py-1 text-gray-800 hover:bg-gray-200 text-xs"
                    >
                      Session<span className="pl-14">F5</span>
                    </Link>
                    <Link
                      to="/OnlineAccount"
                      className="block px-4 py-1 text-gray-800 hover:bg-gray-200 text-xs"
                    >
                      Online Account
                    </Link>
                  </div>
                )}
              </div>*/}
            </li>
            <li>
              <Link
                to="/Compact"
                className="text-gray-800 hover:text-blue-600 underline text-sm"
              >
                Compact (F6)
              </Link>
            </li>
            <li>
              <Link
                to="/TrialBalance"
                className="text-gray-800 hover:text-blue-600 underline text-sm"
              >
                Trial Balance (F8)
              </Link>
            </li>
            <li>
              <Link
                to="/Settelment"
                className="text-gray-800 hover:text-blue-600 underline text-sm"
              >
                Settlement
              </Link>
            </li>
            <li>
              <Link
                to="/ChangePassword"
                className="text-gray-800 hover:text-blue-600 underline text-sm"
              >
                Utilities
              </Link>

              {/*<div className="relative" ref={dropdownRef}>
                <button
                  onClick={toggleDropdown}
                  className="text-black focus:outline-none underline text-sm"
                >
                  Utilities
                </button>
                {dropdownOpen && (
                  <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50 text-justify">
                    <Link
                      to="/ChangePassword"
                      className="block px-4 py-1 text-gray-800 hover:bg-gray-200 text-xs"
                    >
                      ChangePassword
                    </Link>
                    <Link
                      to="/Backup"
                      className="block px-4 py-1 text-gray-800 hover:bg-gray-200 text-xs"
                    >
                      <span></span>Backup
                    </Link>
                    <Link
                      to="/Setting"
                      className="block px-4 py-1 text-gray-800 hover:bg-gray-200 text-xs"
                    >
                      Setting
                    </Link>
                    <Link
                      to="/ClearAllData"
                      className="block px-4 py-1 text-gray-800 hover:bg-gray-200 text-xs"
                    >
                      Claer All Data
                    </Link>
                    <Link
                      to="/ClearAllData"
                      className="block px-4 py-1 text-gray-800 hover:bg-gray-200 text-xs"
                    >
                      Clear All Except Username
                    </Link>
                    <Link
                      to="/ClearAllData"
                      className="block px-4 py-1 text-gray-800 hover:bg-gray-200 text-xs"
                    >
                      Delete All Except User and Account
                    </Link>
                  </div>
                )}
              </div>*/}
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
