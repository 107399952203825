import React from 'react'
import Header from '../components/Header'
import NavBar from '../components/NavBar'

const Master = () => {
  return (
    <div>
      <NavBar/>
     {/* Main Content */}
  <main className="flex-1 container-fluid bg-gray-200 ">
    <div className="bg-gray-200 h- rounded-lg shadow-md p-80">
      {/* Empty content area as per the screenshot */}
    </div>
  </main>
    </div>
  )
}

export default Master
