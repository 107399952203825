import React from 'react'

const Setting = () => {
  return (
    <div>
    
      <div className="bg-gray-200 p-4 rounded-lg  w-[70%]  m-2">
        <h2 className="text-xl font-bold  border border-gray-400 p-2">Settings</h2>
        
        <div className="grid grid-cols-2 gap-4 border border-gray-400 p-2">
          <div className="border p-4">
            <div className='border border-gray-400 w-[100%]  pb-28'>
                <div className='bg-gray-300 border border-b-gray-400 p-1'>
            <h3 className="font-bold mb-2">Settings</h3>
            </div>
            <div className='m-3'>
              <label className="flex items-center text-lg">
                <input type="checkbox" className="mr-2 " />
                Rate Entry Without Decimal
              </label>
            </div>

            </div>
          </div>
          <div className="border ">
          <div className='border border-gray-400 w-[100%]  mt-4'>
          <div className='bg-gray-300 border border-b-gray-400 p-1'>
            <h3 className="font-bold mb-2"> Mode Settings</h3>
            </div>
            <div className="mb-4 mx-32">
              <label className="block text-gray-700 mb-1">Match</label>
              <select className="w-[50] border pr-28 py-1 border border-gray-400">
                <option>K / L</option>
                <option>A / B</option>
                <option>X / Y</option>
              </select>
            </div>

            

            <div className='mx-32 mb-5'>
              <label className="block text-gray-700 mb-1 ">Session</label>
              <select className="w-[20] border px-2 py-1  border border-gray-400 ">
                <option>Yes / No</option>
                <option>On / Off</option>
                <option>Enabled / Disabled</option>
              </select>
            </div>

</div>
          </div>
        </div>

        <p className="text-red-800 mt-4">
          Applied Changes will Affect all the open Modules, It will be close after Save
        </p>

        <div className="flex justify-start mt-4">
          <button 
          
            className="bg-gray-300 text-black px-6 py-1 rounded-md mr-2 hover:bg-gray-400"
          >
            Save
          </button>
          <button 
          
            className="bg-gray-300 text-black px-6 py-1 rounded-md hover:bg-gray-400"
          >
            Cancel
          </button>
        </div>
      </div>
    
    </div>
  )
}

export default Setting
