import React from "react";
import { Link } from "react-router-dom";
import yellow from "../images/yellow gold logo.png";


const Login = () => {
  return (
    <div>
      <>
      
        <div className="font-[sans-serif] h-full mx-auto " id="form1  ">
          <div className="grid md:grid-cols-2 items-center h-full p-32 bg-gray-100">
            <div className="max-md:order-1 bg-gray-100 ">
              <Link to="/Login">
                <img
                  src={yellow}
                  className="lg:max-w-[60%] w-full md:h-[320px] block mx-auto p-20"
                  alt="login-image"
                />
              </Link>
            </div>
            <div className="bg-gray-100 p-1">
              <form className="w-full max-w-sm">
                <p className="text-center text-[#9ca3af] my-10 text-3xl">
                  Yellow Gold Pro-Login
                </p>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-1/3">
                    <label
                      className="block text-black font-normal md:text-right mb-1 md:mb-0 pr-4"
                      htmlFor="inline-full-name"
                    >
                      Username
                    </label>
                  </div>
                  <div className="md:w-2/1">
                    <input
                      className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 
            leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                      id="inline-full-name"
                      type="text"
                      defaultValue="Admin"
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-1/3">
                    <label
                      className="block text-black font-normal md:text-right mb-1 md:mb-0 pr-4"
                      htmlFor="inline-password"
                    >
                      Password
                    </label>
                  </div>
                  <div className="md:w-2/1">
                    <input
                      className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 
            leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                      id="inline-password"
                      type="password"
                      placeholder="******************"
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-1/3" />
                  {/*- <label class="md:w-2/3 block text-gray-500 font-bold">
              <input class="mr-2 leading-tight" type="checkbox">
              <span class="text-sm">
                Send me your newsletter!
              </span>
            </label>*/}
                </div>
                <div className="md:flex md:items-center ">
                  <div className="md:w-1/3" />
                  <div className=" flex md:w-2/3 gap-4">
                    <Link to="./Master">
                      <button
                        className="shadow bg-gray-200 hover:bg-purple-200 focus:shadow-outline 
              focus:outline-none text-black font-xs py-2 px-6 rounded border-gray-500"
                        type="button"
                      >
                        Login
                      </button>
                    </Link>
                    <button
                      className="shadow bg-gray-200 hover:bg-purple-200 
                      focus:shadow-outline focus:outline-none text-black
             font-xs py-2 px-6 rounded border-gray-500"
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Login;
