import React from "react";
import yellow from "../images/yellow gold logo.png";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from 'react';

const Header = () => {

    {/*const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);*/}
  
  return (
    <div>
      <header className="bg-white shadow">
        <div className="container px-4 py-1 ">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <Link to="/Login">
                <img src={yellow} alt="logo" width={15} height={15} />
              </Link>
              <span className="text-sm font-normal text-black">
                YellowGoldPro 1.0 Admin - Days Remaining: 319 - 11-Apr-2022
              </span>
            </div>
            {/*<div class="text-sm text-gray-600">
                Admin - Days Remaining: 319 - 11-Apr-2022
            </div>*/}
          </div>
        </div>
      </header>
      {/* Tabs */}
      {/*<nav className="bg-gray-200 shadow-inner">
        <div className="container px-4 h-10">
          <ul className="flex space-x-4 py-2">
            <div>
              <div className="relative inline-block text-left">
                <div>
                  <button
                    type="button"
                    id="dropdownButton"
                    className="text-gray-800 hover:text-blue-600 underline text-sm"
                  >
                    Master
                  </button>
                </div>
                <div
                  id="dropdownMenu"
                  className="hidden origin-top-right-0 mt-2 w-56 rounded-md
                   shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100"
                >
                  <div className="py-1" role="none">
                    <Link
                      to="/Resgistration"
                      className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                      role="menuitem"
                    >
                      Registration
                    </Link>
                    <Link
                      to="/Client"
                      className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                      role="menuitem"
                    >
                      Client
                    </Link>
                  </div>
                  <div className="py-1" role="none">
                    <Link
                      to="./match.html"
                      className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                      role="menuitem"
                    >
                      Match
                    </Link>
                    <Link
                      to="./session.html"
                      className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                      role="menuitem"
                    >
                      Session
                    </Link>
                  </div>
                  <div className="py-1" role="none">
                    <Link
                      to="#"
                      className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                      role="menuitem"
                    >
                      Online Client
                    </Link>
                  </div>
                  <div className="py-1" role="none">
                    <Link
                      to="/SystemUser"
                      className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                      role="menuitem"
                    >
                      System User
                    </Link>
                  </div>
                  <div className="py-1" role="none">
                    <Link
                      to="#"
                      className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                      role="menuitem"
                    >
                      Exit
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <li>
              <a
                href="./sessiontransaction.html"
                className="text-gray-800 hover:text-blue-600 underline text-sm"
              >
                Transaction
              </a>
            </li>
            <li>
              <Link
                to="/Compact"
                className="text-gray-800 hover:text-blue-600 underline text-sm"
              >
                Compact (F6)
              </Link>
            </li>
            <li>
              <Link
                to="/TrialBalance"
                className="text-gray-800 hover:text-blue-600 underline text-sm"
              >
                Trial Balance (F8)
              </Link>
            </li>
            <li>
              <Link
                to="/Settelment"
                className="text-gray-800 hover:text-blue-600 underline text-sm"
              >
                Settlement
              </Link>
            </li>
            <li>
              <Link
                to="./clientpopup.html"
                className="text-gray-800 hover:text-blue-600 underline text-sm"
              >
                Utilities
              </Link>
            </li>
          </ul>
        </div>
      </nav>*/}

{/*<nav className="bg-gray-200 p-4">
      <div className="container mx-auto flex justify-between items-center">*/}
      {/*<nav className="bg-gray-200 shadow-inner">
      <div className="container px-4 h-10">
     
      <ul className="flex space-x-4 py-2">
        <div className="relative" ref={dropdownRef}>
          <button
            onClick={toggleDropdown}
            className="text-black focus:outline-none underline text-sm"
          >
            Master
          </button>
          {dropdownOpen && (
            <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50 text-justify">
              <Link to="Register" className="block px-4 py-2 text-gray-800 hover:bg-gray-200 text-sm">
               <span></span> Registration
              </Link>
              <Link to="Client" className="block px-4 py-2 text-gray-800 hover:bg-gray-200 text-sm">
                Client
              </Link>
              <Link to="/Match" className="block px-4 py-2 text-gray-800 hover:bg-gray-200 text-sm">
                Match
              </Link>
              <Link to="/Session" className="block px-4 py-2 text-gray-800 hover:bg-gray-200 text-sm">
                Session
              </Link>
              <Link to="/Client" className="block px-4 py-2 text-gray-800 hover:bg-gray-200 text-sm">
                Online Client
              </Link>
              <Link to="/SystemUser" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                System User
              </Link>
              <Link to="" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                Exit
              </Link>
            </div>
          )}
        </div>
        <li>
        <Link
                to="/Tra"
                className="text-gray-800 hover:text-blue-600 underline text-sm"
              >
               Transaction
              </Link>
       
            </li>
            <li>
              <Link
                to="/Compact"
                className="text-gray-800 hover:text-blue-600 underline text-sm"
              >
                Compact (F6)
              </Link>
            </li>
            <li>
              <Link
                to="/TrialBalance"
                className="text-gray-800 hover:text-blue-600 underline text-sm"
              >
                Trial Balance (F8)
              </Link>
            </li>
            <li>
              <Link
                to="/Settelment"
                className="text-gray-800 hover:text-blue-600 underline text-sm"
              >
                Settlement
              </Link>
            </li>
            <li>
              <Link
                to="./clientpopup.html"
                className="text-gray-800 hover:text-blue-600 underline text-sm"
              >
                Utilities
              </Link>
            </li>
        </ul>
      </div>
    </nav>*/}
    </div>








  );
};

export default Header;
