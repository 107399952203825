import React from 'react'
import NavBar from '../components/NavBar'

const MatchTransaction = () => {
  return (
    <div>
        <NavBar/>
      <div className="container bg-gray-300 p-4 rounded-lg shadow-lg mx-auto m-2">
  {/* Main Book */}
  {/* Match Information */}
  <div>
    <h2 className="font-normal text-m">Main book </h2>
  </div>
  <div className="grid grid-cols-2 md:grid-cols-5 gap-4 flex">
    <div className="p-4 rounded-lg shadow-sm bg-gray-200">
      {/*<div class="flex ">
    <h2 class="text-lg font-semibold mb-2">Main Book</h2>

    <button class="text-blue-500 text-sm justify-end">More &gt;</button>

  </div>*/}
      <div className="flex justify-between items-center">
        <div className="md:w-1/3">
          <label
            className="block text-black font-bold md:text-right mb-1 md:mb-0 pr-4"
            htmlFor="inline-full-name"
          >
            Team1
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-white appearance-none border-2 border-gray-300 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="inline-full-name"
            type="text"
            defaultValue=""
          />
        </div>
        {/*<div>INDIA</div>
      <div class="text-red-500">-8400.00</div>*/}
      </div>
      <div className="flex justify-between items-center">
        <div className="md:w-1/3">
          <label
            className="block text-black font-bold md:text-right mb-1 md:mb-0 pr-4"
            htmlFor="inline-full-name"
          >
            Team2
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-white appearance-none border-2 border-gray-300 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="inline-full-name"
            type="text"
            defaultValue=""
          />
        </div>
        {/*<div>LANKA</div>
      <div class="text-blue-500">14700.00</div>*/}
      </div>
      <div className="flex justify-between items-center">
        <div className="md:w-1/3">
          <label
            className="block text-black font-bold md:text-right mb-1 md:mb-0 pr-4"
            htmlFor="inline-full-name"
          >
            Team3
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-white appearance-none border-2 border-gray-300 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="inline-full-name"
            type="text"
            defaultValue=""
          />
        </div>
      </div>
    </div>
    <div className="bg-gray-200 p-4 rounded-lg shadow-sm">
      <div className="flex justify-between items-center">
        <div className="md:w-1/3">
          <label
            className="block text-black font-bold md:text-right mb-1 md:mb-0 pr-4"
            htmlFor="inline-full-name"
          >
            Avg
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-white appearance-none border-2 border-gray-300 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="inline-full-name"
            type="text"
            defaultValue=""
          />
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="md:w-1/3">
          <label
            className="block text-black font-bold md:text-right mb-1 md:mb-0 pr-4"
            htmlFor="inline-full-name"
          >
            Avg
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-white appearance-none border-2 border-gray-300 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="inline-full-name"
            type="text"
            defaultValue=""
          />
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="md:w-1/3">
          <label
            className="block text-black font-bold md:text-right mb-1 md:mb-0 pr-4"
            htmlFor="inline-full-name"
          >
            Avg
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-white appearance-none border-2 border-gray-300 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="inline-full-name"
            type="text"
            defaultValue=""
          />
        </div>
      </div>
    </div>
  </div>
  <div className="flex justify-end items-center mb-4">
    <div className="space-x-2">
      <button className="bg-gray-200 px-4 py-2 rounded-md">Delete</button>
      <button className="bg-gray-200 px-4 py-2 rounded-md">Show Deleted</button>
      <button className="bg-gray-200 px-4 py-2 rounded-md">Refresh</button>
      <button className="bg-gray-200 px-4 py-2 rounded-md">Exit</button>
    </div>
  </div>
  <div className="bg-gray-200 p-4 rounded-lg shadow-sm mt-5">
    <div className="flex justify-between items-center mb-2">
      <div className="flex">
        Match name
        <form className="flex items-center">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" />
            <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-28 py-1 pr-10 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
              <option />
              <option>Option 2</option>
              <option>Option 3</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </form>
      </div>
      <div>Date: 04/03/2022</div>
      {/*<div class="flex items-center space-x-4">*/}
      <label className="flex items-center space-x-1">
        <input type="checkbox" className="form-checkbox" />
        <span>Time</span>
      </label>
      <label className="flex items-center space-x-1">
        <input type="checkbox" className="form-checkbox" />
        <span>Dabba</span>
      </label>
      <label className="flex items-center space-x-1">
        <input type="checkbox" className="form-checkbox" />
        <span>Patti</span>
      </label>
      <label className="flex items-center space-x-1">
        <input type="checkbox" className="form-checkbox" />
        <span>Calculate Patti</span>
      </label>
    </div>
  </div>
  {/*<div class="mb-4 flex justify-between items-center">
  <!--<div class=" flex p-4 rounded-lg shadow-sm">
    <h2 class="text-lg font-semibold mb-2">Main Book</h2>

    <div class="flex justify-between items-center mb-2">
      <div>INDIA</div>
      <div class="text-red-500">-8400.00</div>
    </div>
    <div class="flex justify-between items-center">
      <div>LANKA</div>
      <div class="text-blue-500">14700.00</div>
    </div>
  </div>*/}
  {/*<div>
    <label class="block text-gray-700">Match Name</label>
    <input
      type="text"
      value="IND VS LANKA"
      class="w-full p-2 mt-1 border rounded-md"
    />
  </div>
  <div>
    <label class="block text-gray-700">Match Date</label>
    <input
      type="date"
      value="2022-04-03"
      class="w-full p-2 mt-1 border rounded-md"
    />
  </div>
  <div class="flex items-center space-x-4">
    <input
      type="checkbox"
      class="form-checkbox h-5 w-5 text-gray-600"
      checked
    />
    <label class="text-gray-700">Time</label>
    <input
      type="checkbox"
      class="form-checkbox h-5 w-5 text-gray-600"
      checked
    />
    <label class="text-gray-700">Dabba</label>
    <input
      type="checkbox"
      class="form-checkbox h-5 w-5 text-gray-600"
      checked
    />
    <label class="text-gray-700">Patti</label>
    <input
      type="checkbox"
      class="form-checkbox h-5 w-5 text-gray-600"
      checked
    />
    <label class="text-gray-700">Calculate Patti</label>
  </div>
</div>*/}
  <div className="flex justify-between items-center bg-gray-200 p-3 shadow-sm border-2 border-gray-300 mt-2">
    <div className="w-full max-w-md ml-2">
      <form className="flex items-center">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" />
          <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-0 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
            <option />
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
          {/*<input
        type="text"
        id="search"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
        focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
        placeholder=""
      />*/}
        </div>
        <button
          type="submit"
          className="ml-2 text-black bg-gray-200 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-0 border border-gray-600"
        >
          Find
        </button>
        <button
          type="submit"
          className="ml-2 text-black bg-gray-200 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-0 border border-gray-600"
        >
          Clear
        </button>
      </form>
    </div>
  </div>
  {/* Table */}
  <div className="overflow-x-auto">
    <table className="min-w-full bg-white border rounded-lg">
      <thead>
        <tr className="bg-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
          <th className="p-2">
            <input type="checkbox" className="form-checkbox h-5 w-5" />
          </th>
          <th className="p-2">Sr</th>
          <th className="p-2">Rate</th>
          <th className="p-2">Amount</th>
          <th className="p-2">Mode</th>
          <th className="p-2">Team</th>
          <th className="p-2">Client Name</th>
          <th className="p-2">User Book (INDIA)</th>
          <th className="p-2">User Book (LANKA)</th>
          <th className="p-2">User Comm. (INDIA)</th>
          <th className="p-2">User Comm. (LANKA)</th>
          <th className="p-2">Reff. Comm. (INDIA)</th>
          <th className="p-2">Reff. Comm. (LANKA)</th>
          <th className="p-2">Patti (INDIA)</th>
          <th className="p-2">Patti (LANKA)</th>
          <th className="p-2">Patti Comm. (INDIA)</th>
          <th className="p-2">Patti Comm. (LANKA)</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {/* Add your data rows here */}
        <tr className="bg-white">
          <td className="p-2">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5"
              defaultChecked=""
            />
          </td>
          <td className="p-2">6</td>
          <td className="p-2">0.3400</td>
          <td className="p-2">10000</td>
          <td className="p-2">K</td>
          <td className="p-2">INDIA</td>
          <td className="p-2">SANWARIYA</td>
          <td className="p-2">-3400</td>
          <td className="p-2">10000</td>
          <td className="p-2">0.00</td>
          <td className="p-2">-400.00</td>
          <td className="p-2">0.00</td>
          <td className="p-2">0.00</td>
          <td className="p-2">0.00</td>
          <td className="p-2">0.00</td>
          <td className="p-2">0.00</td>
          <td className="p-2">0.00</td>
        </tr>
        <tr className="bg-white">
          <td className="p-2">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5"
              defaultChecked=""
            />
          </td>
          <td className="p-2">4</td>
          <td className="p-2">0.7800</td>
          <td className="p-2">10000</td>
          <td className="p-2">K</td>
          <td className="p-2">INDIA</td>
          <td className="p-2">SANWARIYA</td>
          <td className="p-2">-7800</td>
          <td className="p-2">10000</td>
          <td className="p-2">0.00</td>
          <td className="p-2">0.00</td>
          <td className="p-2">0.00</td>
          <td className="p-2">0.00</td>
          <td className="p-2">0.00</td>
          <td className="p-2">0.00</td>
          <td className="p-2">0.00</td>
          <td className="p-2">0.00</td>
        </tr>
        {/* Add more rows as needed */}
      </tbody>
    </table>
  </div>
  {/* Footer */}
  <div className="mt-4 flex justify-between items-center gap-4">
    {/*<div class="flex space-x-2">
    <button class="bg-gray-200 px-4 py-2 rounded-md">Delete</button>
    <button class="bg-gray-200 px-4 py-2 rounded-md">Show Deleted</button>
    <button class="bg-gray-200 px-4 py-2 rounded-md">Refresh</button>
    <button class="bg-gray-200 px-4 py-2 rounded-md">Exit</button>
  </div>*/}
    <button className="bg-gray-200 px-4 py-1 rounded-md mt-5">Edit</button>
    <div>
      <label className="block text-black">Rate</label>
      <input type="text" className="w-full py-1 mt-1 border rounded-md" />
    </div>
    <div>
      <label className="block text-black">Amount</label>
      <input type="text" className="w-full mt-1 border rounded-md py-1" />
    </div>
    <div>
      <label className="block text-black" >Mode</label>
      <select className="border border-gray-300 px-8 rounded w-full bg-white py-1">
        <option />
        {/* Add more options as necessary */}
      </select>
    </div>
    <div>
      <label className="block text-black">Support Team</label>
      <select className="border border-gray-300 px-14 rounded w-full bg-white py-1">
        <option />
        {/* Add more options as necessary */}
      </select>
    </div>
    <div>
      <label className="block text-black">Client Team</label>
      <select className="border border-gray-300 px-24 rounded w-full bg-white py-1">
        <option />
        {/* Add more options as necessary */}
      </select>
    </div>
    <div className="mt-6">
      <button className="bg-gray-200 px-4 py-1 rounded-md">Save</button>
    </div>
    <div className="mt-5">
      <input type="text" className="w-full py-1 mt-1 border rounded-md" />
    </div>
    <div className="mt-5">
      <input type="text" className="w-full py-1 mt-1 border rounded-md" />
    </div>
    <div className="">
      <input
        type="checkbox"
        className="form-checkbox h-5 w-5 text-gray-600"
        defaultChecked=""
      />
      <label className="text-black">Comm. Y/N</label>
      <input type="text" className="w-full py-1 mt-1 border rounded-md" />
    </div>
    <div className="flex gap-2">
      <button className="bg-gray-200 px-6 py-0 rounded-md">Watch List</button>
      <button className="bg-gray-200 px-6 py-0 rounded-md">Client Book</button>
    </div>
    {/*<div>

     
    <input
      type="checkbox"
      class="form-checkbox h-5 w-5 text-gray-600"
      checked
    />
    <label class="text-gray-700">Comm. Y/N</label>
  </div>
  <div>
    
    <button class="bg-gray-200 px-4 py-2 rounded-md">Watch List</button>
    <button class="bg-gray-200 px-4 py-2 rounded-md">Client Book</button>
  </div>*/}
  </div>
</div>

    </div>
  )
}

export default MatchTransaction
