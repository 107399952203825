import React from 'react'

const OnlineClient = () => {
  return (
    <div>
      <>
  <div className="flex justify-between items-center bg-gray-200 p-3  shadow-sm mx-3 mt-2">
    <div className="w-full max-w-md  ml-2">
      <form className="flex items-center">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"></div>
          <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
            <option />
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
          {/*<input
        type="text"
        id="search"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
        focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
        placeholder=""
      />*/}
        </div>
        <button
          type="submit"
          className="ml-2 text-black bg-gray-200 hover:bg-blue-700 focus:ring-4 focus:outline-none
       focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 border border-gray-600"
        >
          Find
        </button>
        <button
          type="submit"
          className="ml-2 text-black bg-gray-200 hover:bg-blue-700 focus:ring-4 
      focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 border border-gray-600"
        >
          Clear
        </button>
      </form>
    </div>
  </div>
  {/*---end*/}
  <div className="flex flex-col overflow-x-auto bg-white mx-3">
    <div className="sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
        <div className="overflow-x-auto ">
        
          <table className="min-w-full text-start text-sm font-light text-surface dark:text-black">
            <thead className="border-b border-neutral-200 font-medium dark:border-white/10">
              <tr className="divide-x bg-gray-100">
                <th scope="col" className="px-6">
                  Settlement
                </th>
                <th scope="col" className="px-6">
                  Act
                </th>
                <th scope="col" className="px-6">
                  Tallied
                </th>
                <th scope="col" className="px-6">
                  Client Name
                </th>
                <th scope="col" className="px-6">
                  Display Name
                </th>
                <th scope="col" className="px-6">
                  User type
                </th>
                <th scope="col" className="px-6">
                  Exchange Name
                </th>
                <th scope="col" className="px-6">
                  Client (P/L)
                </th>
                <th scope="col" className="px-6">
                  @
                </th>
                <th scope="col" className="px-6">
                  Client Comm.
                </th>
                <th scope="col" className="px-6">
                  Client Amount
                </th>
                <th scope="col" className="px-6">
                  Parent Id
                </th>
                <th scope="col" className="px-6">
                  Parent @
                </th>
                <th scope="col" className="px-6">
                  Parent Comm.
                </th>
                <th scope="col" className="px-6">
                  Parent Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className=" border-neutral-200 dark:border-white/10 divide-x te">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center"></div>
                </td>
                <td className="whitespace-nowrap px-6 bg-[#fef08a]  " />
                <td className="whitespace-nowrap px-6 bg-[#fef08a]" />
                <td className="whitespace-nowrap px-6 bg-[#fef08a]" />
                <td className="whitespace-nowrap px-6 bg-[#fef08a]"></td>
                <td className="whitespace-nowrap px-6 bg-[#fef08a]" />
                <td className="whitespace-nowrap px-6 bg-[#fef08a]" />
                <td className="whitespace-nowrap px-6 bg-[#fef08a]" />
                <td className="whitespace-nowrap px-6 bg-[#fef08a]" />
                <td className="whitespace-nowrap px-6 bg-[#fef08a]" />
                <td className="whitespace-nowrap px-6 bg-[#fef08a]" />
                <td className="whitespace-nowrap px-6 bg-[#fef08a]" />
                <td className="whitespace-nowrap px-6 bg-[#fef08a]" />
                <td className="whitespace-nowrap px-6 bg-[#fef08a]" />
                <td className="whitespace-nowrap px-6 bg-[#fef08a]" />
              </tr>
              <tr className=" border-neutral-200 dark:border-white/10 divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center"></div>
                </td>
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
              </tr>
              <tr className=" divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center"></div>
                </td>
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
              </tr>
              <tr className=" divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center"></div>
                </td>
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
              </tr>
              <tr className=" divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center"></div>
                </td>
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
                <td className="whitespace-nowrap px-6 " />
              </tr>
              <tr className="border-b divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6">LOKESH</td>
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">3</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]" />
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">2</td>
                <td className="whitespace-nowrap px-6 bg-[#5eead4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">Nett</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#fef9c3]">2</td>
              </tr>
              <tr className="border-b divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6">NAVNEET</td>
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]" />
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#5eead4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">Nett</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#fef9c3]">0</td>
              </tr>
              <tr className="border-b divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                  focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6">P &amp; L ACC</td>
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]" />
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]" />
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#5eead4]" />
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">Nett</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#fef9c3]">0</td>
              </tr>
              <tr className="border-b divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6">PRADEEP DAIRY</td>
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6">SAPPIREEXCH</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]" />
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#5eead4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">Nett</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#fef9c3]">0</td>
              </tr>
              <tr className="border-b divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6">RAJA</td>
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]" />
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#5eead4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">Nett</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#fef9c3]">2</td>
              </tr>
              <tr className="border-b divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6">RECE</td>
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6">SAPPIREEXCH</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">Receive</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">SONU YG</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#5eead4]">Receive</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">Nett</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">SONU YG</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#fef9c3]">0</td>
              </tr>
              <tr className="border-b divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6">SANWARIYA</td>
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6">LOKESH JI</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">3</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]" />
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">2</td>
                <td className="whitespace-nowrap px-6 bg-[#5eead4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">Nett</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#fef9c3]">1</td>
              </tr>
              <tr className="border-b divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6">SAPPIREEXCH</td>
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6">SAPPIREEXCH</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]" />
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#5eead4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">Nett</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">0</td>
                <td className="whitespace-nowrap px-6 bg-[#fef9c3]">0</td>
              </tr>
              <tr className="border-b divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6">SONU YG</td>
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6">SAPPIREEXCH</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">BHANU</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#5eead4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">Nett</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">BHANU</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#fef9c3]">0</td>
              </tr>

              <tr className="border-b divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6">SONU YG</td>
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6">SAPPIREEXCH</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">BHANU</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#5eead4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">Nett</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">BHANU</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#fef9c3]">0</td>
              </tr>

              <tr className="border-b divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6">SONU YG</td>
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6">SAPPIREEXCH</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">BHANU</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#5eead4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">Nett</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">BHANU</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#fef9c3]">0</td>
              </tr>

              <tr className="border-b divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6">SONU YG</td>
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6">SAPPIREEXCH</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">BHANU</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#5eead4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">Nett</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">BHANU</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#fef9c3]">0</td>
              </tr>

              <tr className="border-b divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6">SONU YG</td>
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6">SAPPIREEXCH</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">BHANU</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#5eead4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">Nett</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">BHANU</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#fef9c3]">0</td>
              </tr>

              <tr className="border-b divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6">SONU YG</td>
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6">SAPPIREEXCH</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">BHANU</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#5eead4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">Nett</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">BHANU</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#fef9c3]">0</td>
              </tr>

              <tr className="border-b divide-x">
                <td className="whitespace-nowrap px-6 font-medium">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6">SONU YG</td>
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6" />
                <td className="whitespace-nowrap px-6">SAPPIREEXCH</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">BHANU</td>
                <td className="whitespace-nowrap px-6 bg-[#99f6e4]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#5eead4]">Pay</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">Nett</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">BHANU</td>
                <td className="whitespace-nowrap px-6 bg-[#4ade80]">5</td>
                <td className="whitespace-nowrap px-6 bg-[#fef9c3]">0</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div className="flex space-x-2 bg-gray-200 p-2 mx-3 mb-3">
    <button className="bg-gray-200 px-4 py-2 rounded-md border border-black">
      Add/Edit
    </button>
    <button className="bg-gray-200 px-4 py-2 rounded-md border border-black">
      Save
    </button>
    <button className="bg-gray-200 px-4 py-2 rounded-md border border-black">
      Exit
    </button>
    <button className="bg-gray-200 px-4 py-2 rounded-md border border-black">
      File Upload
    </button>
    <button className="bg-gray-200 px-4 py-2 rounded-md border border-black">
      Clear
    </button>
    <button className="bg-gray-200 px-4 py-2 rounded-md border border-black">
      Settlement
    </button>
    <button className="bg-gray-200 px-4 py-2 rounded-md border border-black">
      New
    </button>
    <div className="flex justify-end"></div>
    <div className="flex justify-end">
      <p className="text-[#7c2d12] pt-2">
        (Ctrl+Del) to Clear list value.Double clicks on Patti Details cell to
        enter Patti Refernce
      </p>
    </div>
  </div>
</>

    </div>
  )
}

export default OnlineClient
