import React from "react";
import NavBar from "../components/NavBar";
import { TfiSave } from "react-icons/tfi";
import { IoIosMore } from "react-icons/io";

const Backup = () => {
  return (



    
    <div>
       
       <NavBar/>

      <div className="flex  items-left h-70 bg-white">
        <div className="bg-gray-200 p-2 rounded  w-full m-2 border border-gray-300 ">
          <div className="w-[60%]">
            <h2 className="text-m font-bold mb-4">Select Backup Location</h2>

            <div className="md:flex  mb-1 ">
              <div className="md:w-1/2">
                <label
                  className="block text-black font-normal md:text-right mb-1 md:mb-0 pr-6"
                  htmlFor="inline-full-name"
                >
                  Backup Path
                </label>
              </div>
              <div className="md:w-2/1 pr-2">
                <input
                  className="appearance-none border-2 border-gray-300 rounded w-full py-1 px-64 text-gray-700 
            leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                  id="inline-full-name"
                  type="text"
                  defaultValue=""
                />
              </div>

              <div className="flex">
              <button
                type="button"
                class="text-blue-700 border border-gray-400 hover:bg-blue-700
               hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
                rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500
                 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800
                  dark:hover:bg-blue-500"
              >
                <IoIosMore />
                <span class="sr-only"></span>
              </button>

              <button
                type="button"
                class="text-black border border-gray-400 hover:bg-blue-700 
               hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
                rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-gray-400
                 dark:text-black dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500"
              >
             <TfiSave />
                <span class="sr-only w"></span>
              </button>
              </div>
            </div>

            <div className="flex justify-left gap-2 p-3 pl-40">
              <button
                className="bg-gray-300 text-black px-8 py-1 rounded-md
               hover:bg-gray-400 border border-gray-400"
              >
                OK
              </button>
              <button
                className="bg-gray-300 text-black px-6 py-1
               rounded-md hover:bg-gray-400 border border-gray-400"
              >
                Cancel
              </button>
            </div>

            <div className="pb-5">
              <p className="text-left pl-40 font-normal text-m">
                Select Path For Backup
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Backup;
