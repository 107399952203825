import React from "react";
import NavBar from "../components/NavBar";

const ChangePassword = () => {
  return (
    <div>
        <NavBar/>
      <div className="flex  items-left h-70 bg-white">
        <div className="bg-gray-200 p-2 rounded  w-full m-2 border border-gray-300 ">
          <div className="w-[33%]">
            <h2 className="text-m font-bold mb-4">
              Enter your password detail
            </h2>

            <div className="md:flex  mb-1 ">
              <div className="md:w-1/2">
                <label
                  className="block text-black font-normal md:text-right mb-1 md:mb-0 pr-4"
                  htmlFor="inline-full-name"
                >
                  Username
                </label>
              </div>
              <div className="md:w-2/1">
                <input
                  className="appearance-none border-2 border-gray-300 rounded w-full py-1 px-12 text-gray-700 
            leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                  id="inline-full-name"
                  type="text"
                  defaultValue=""
                />
              </div>
            </div>

            <div className="md:flex  mb-1 ">
              <div className="md:w-1/2">
                <label
                  className="block text-black font-normal md:text-right mb-1 md:mb-0 pr-4"
                  htmlFor="inline-full-name"
                >
                  Current Password
                </label>
              </div>
              <div className="md:w-2/1">
                <input
                  className="appearance-none border-2 border-gray-300 rounded w-full py-1 px-12 text-gray-700 
            leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                  id="inline-full-name"
                  type="text"
                  defaultValue=""
                />
              </div>
            </div>

            <div className="md:flex  mb-1">
              <div className="md:w-1/2">
                <label
                  className="block text-black font-normal md:text-right mb-1 md:mb-0 pr-4"
                  htmlFor="inline-full-name"
                >
                  New Password
                </label>
              </div>
              <div className="md:w-2/1">
                <input
                  className="appearance-none border-2 border-gray-300 rounded w-full py-1 px-12 text-gray-700 
            leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                  id="inline-full-name"
                  type="text"
                  defaultValue=""
                />
              </div>
            </div>

            <div className="md:flex  mb-1">
              <div className="md:w-1/2">
                <label
                  className="block text-black font-normal md:text-right mb-1 md:mb-0 pr-4"
                  htmlFor="inline-full-name"
                >
                  Confirm Password
                </label>
              </div>
              <div className="md:w-2/1">
                <input
                  className="appearance-none border-2 border-gray-300 rounded w-full py-1 px-12 text-gray-700 
            leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                  id="inline-full-name"
                  type="text"
                  defaultValue=""
                />
              </div>
            </div>

            <div className="flex justify-end gap-2">
              <button className="bg-gray-300 text-black px-8 py-1 rounded-md hover:bg-gray-400 border border-gray-300">
                OK
              </button>
              <button className="bg-gray-300 text-black px-6 py-1 rounded-md hover:bg-gray-400 border border-gray-300">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
