import logo from './logo.svg';
import './App.css';
import Register from './pages/Register';
import Client from './pages/Client';
import Header from './components/Header';
import Match from './pages/Match';
import Compact from './pages/Compact';


import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Settlement from './pages/Settlement';
import TrialBalance from './pages/TrialBalance';
import Clientwise from './pages/Clientwise';
import Groupwise from './pages/Groupwise';
import Session from './pages/Session';
import OnlineClient from './pages/OnlineClient';
import SystemUser from './pages/SystemUser';
import Master from './pages/Master';
import MatchTransaction from './pages/MatchTransaction';
import SessionTransaction from './pages/SessionTransaction';
import OnlineAccount from './pages/OnlineAccount';
import ChangePassword from './pages/ChangePassword';
import Backup from './pages/Backup';
import Setting from './pages/Setting';
import ClearAllData from './pages/ClearAllData';


function App() {
  return (
    <div>
      <BrowserRouter>
      <Header/>
      <Routes>
      <Route path='/' element={<Login/>}/>
      
        
        <Route path='/Register' element={<Register/>}/>
        <Route path='/Client' element={<Client/>}/>
        <Route path='/Match' element={<Match/>}/>
        <Route path='/Compact' element={<Compact/>}/>
        <Route path='/Settelment' element={<Settlement/>}/>
        <Route path='/TrialBalance' element={<TrialBalance/>}/>
        <Route path='/Clientwise' element={<Clientwise/>}/>
        <Route path='/Groupwise' element={<Groupwise/>}/>
        <Route path='/Session' element={<Session/>}/>
        <Route path='/OnlineClient' element={<OnlineClient/>}/>
        <Route path='/SystemUser' element={<SystemUser/>}/>
        <Route path='/Master' element={<Master/>}/>
        <Route path='/MatchTransaction' element={<MatchTransaction/>}/>
        <Route path='/SessionTransaction' element={<SessionTransaction/>}/>
        <Route path='/OnlineAccount' element={<OnlineAccount/>}/>
        <Route path='/ChangePassword' element={<ChangePassword/>}/>
        <Route path='/Backup' element={<Backup/>}/>
        <Route path='/Setting' element={<Setting/>}/>
        <Route path='/ClearAllData' element={<ClearAllData/>}/>
       
        
       
       
       
       
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
