import React from 'react'
import NavBar from '../components/NavBar'

const Match = () => {
  return (
    <div>
      <>
  <NavBar/>
  <div className=" mx-auto bg-white p-2 rounded-lg shadow-lg">
    <div className="flex">
      <div className="overflow-x-auto w-[65%]">
        <table className="min-w-full bg-white border rounded-lg">
          <thead className="divide-x">
            <tr className="bg-gray-200 text-left text-m font-bold text-black  tracking-wider">
              <th className="p-2">Code</th>
              <th className="p-2">Date</th>
              <th className="p-2">Time</th>
              <th className="p-2">Match Name</th>
              <th className="p-2">Declare</th>
              <th className="p-2">Commt.</th>
              <th className="p-2">Won Team.</th>
              <th className="p-2">Active</th>
            </tr>
          </thead>
          <tbody className="divide-y divide divide-gray-200">
            {/* Add your data rows here */}
            <tr className="divide-x">
              <td className="p-2 text-right ">6</td>
              <td className="p-2">04/04/2022</td>
              <td className="p-2" />
              <td className="p-2" />
              <td className="p-2"></td>
              <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                  focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                   dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </th>
              <td className="p-2" />
              <td className="p-2">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                  focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                   dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </td>
            </tr>
            <tr className="divide-x">
              <td className="p-2 text-right ">6</td>
              <td className="p-2">04/04/2022</td>
              <td className="p-2" />
              <td className="p-2" />
              <td className="p-2"></td>
              <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                    focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                     dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </th>
              <td className="p-2" />
              <td className="p-2">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                    focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                     dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </td>
            </tr>
            <tr className="divide-x">
              <td className="p-2 text-right ">6</td>
              <td className="p-2">04/04/2022</td>
              <td className="p-2" />
              <td className="p-2" />
              <td className="p-2"></td>
              <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                    focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                     dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </th>
              <td className="p-2" />
              <td className="p-2">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                    focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                     dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </td>
            </tr>
            <tr className="divide-x">
              <td className="p-2 text-right ">6</td>
              <td className="p-2">04/04/2022</td>
              <td className="p-2" />
              <td className="p-2" />
              <td className="p-2"></td>
              <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                    focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                     dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </th>
              <td className="p-2" />
              <td className="p-2">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                    focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                     dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </td>
            </tr>
            <tr className="divide-x">
              <td className="p-2 text-right ">6</td>
              <td className="p-2">04/04/2022</td>
              <td className="p-2" />
              <td className="p-2" />
              <td className="p-2"></td>
              <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                    focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                     dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </th>
              <td className="p-2" />
              <td className="p-2">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                    focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                     dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </td>
            </tr>
            <tr className="divide-x">
              <td className="p-2 text-right ">6</td>
              <td className="p-2">04/04/2022</td>
              <td className="p-2" />
              <td className="p-2" />
              <td className="p-2"></td>
              <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                    focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                     dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </th>
              <td className="p-2" />
              <td className="p-2">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                    focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                     dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </td>
            </tr>
            <tr className="divide-x">
              <td className="p-2 text-right ">6</td>
              <td className="p-2">04/04/2022</td>
              <td className="p-2" />
              <td className="p-2" />
              <td className="p-2"></td>
              <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                    focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                     dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </th>
              <td className="p-2" />
              <td className="p-2">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                    focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                     dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </td>
            </tr>
            <tr className="divide-x">
              <td className="p-2 text-right ">6</td>
              <td className="p-2">04/04/2022</td>
              <td className="p-2" />
              <td className="p-2" />
              <td className="p-2"></td>
              <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                    focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                     dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </th>
              <td className="p-2" />
              <td className="p-2">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                    focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                     dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </td>
            </tr>
            <tr className="divide-x">
              <td className="p-2 text-right ">6</td>
              <td className="p-2">04/04/2022</td>
              <td className="p-2" />
              <td className="p-2" />
              <td className="p-2"></td>
              <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                    focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                     dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </th>
              <td className="p-2" />
              <td className="p-2">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded 
                    focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                     dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="overflow-x-auto w-[15%] gap-1 ">
        <div className="font-bold  bg-gray-300 p-2 ">
          <h2>Teams</h2>
        </div>
        <table className="min-w-full bg-white border rounded-lg ">
          <thead className="divide-x p-5 ">
            <tr className="bg-gray-200 text-left text-m font-bold text-black  tracking-wider m-3">
              <th className="p-2">Team Name</th>
            </tr>
          </thead>
          <tbody className="divide-y divide divide-gray-200 p-3">
            {/* Add your data rows here */}
            <tr className="divide-x">
              <td className="p-2">CASH</td>
            </tr>
            <tr className="divide-x">
              <td className="p-2">FC</td>
            </tr>
            <tr className="divide-x">
              <td className="p-2">NAVNEET</td>
            </tr>
            <tr className="divide-x">
              <td className="p-2">SANWARIYA</td>
            </tr>
            <tr className="divide-x">
              <td className="p-2">SONU YC</td>
            </tr>
            <tr className="divide-x">
              <td className="p-2">SONU YC</td>
            </tr>
            <tr className="divide-x">
              <td className="p-2">SONU YC</td>
            </tr>
            <tr className="divide-x">
              <td className="p-2">SONU YC</td>
            </tr>
            <tr className="divide-x">
              <td className="p-2">SONU YC</td>
            </tr>
            <tr className="divide-x">
              <td className="p-2">SONU YC</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="overflow-x-auto w-[25%] ">
        <div className="font-bold  bg-gray-300 p-2 ">
          <h2>Partnership%</h2>
        </div>
        <table className="min-w-full bg-white border rounded-lg">
          <thead className="divide-x">
            <tr className="bg-gray-200 text-left text-m font-bold text-black  tracking-wider">
              <th className="p-2">Party name</th>
              <th className="p-2">Patti %</th>
              <th className="p-2">Sess. %</th>
            </tr>
          </thead>
          <tbody className="divide-y divide divide-gray-200">
            {/* Add your data rows here */}
            <tr className="divide-x">
              <th scope="col" className="p-4"></th>
              <td className="p-2" />
              <td className="p-2" />
            </tr>
            <tr className="divide-x">
              <th scope="col" className="p-4"></th>
              <td className="p-2" />
              <td className="p-2" />
            </tr>
            <tr className="divide-x">
              <th scope="col" className="p-4"></th>
              <td className="p-2" />
              <td className="p-2" />
            </tr>
            <tr className="divide-x">
              <th scope="col" className="p-4"></th>
              <td className="p-2" />
              <td className="p-2" />
            </tr>
            <tr className="divide-x">
              <th scope="col" className="p-4"></th>
              <td className="p-2" />
              <td className="p-2" />
            </tr>
            <tr className="divide-x">
              <th scope="col" className="p-4"></th>
              <td className="p-2" />
              <td className="p-2" />
            </tr>
            <tr className="divide-x">
              <th scope="col" className="p-4"></th>
              <td className="p-2" />
              <td className="p-2" />
            </tr>
            <tr className="divide-x">
              <th scope="col" className="p-4"></th>
              <td className="p-2" />
              <td className="p-2" />
            </tr>
            <tr className="divide-x">
              <th scope="col" className="p-4"></th>
              <td className="p-2"></td>
              <td className="p-2" />
            </tr>
            <tr className="divide-x">
              <th scope="col" className="p-4"></th>
              <td className="p-2"></td>
              <td className="p-2" />
            </tr>
            <tr className="divide-x">
              <th scope="col" className="p-4"></th>
              <td className="p-2"></td>
              <td className="p-2" />
            </tr>
            <tr className="divide-x">
              <th scope="col" className="p-4"></th>
              <td className="p-2"></td>
              <td className="p-2" />
            </tr>
            <tr className="divide-x bg-gray-200">
              <th scope="col" className="p-4"></th>
              <td className="p-2 bg-gray-200">
                <input
                  className="bg-white appearance-none border-2 border-gray-300 rounded w-full py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  id="inline-full-name"
                  type="text"
                  defaultValue=""
                />
              </td>
              <td className="p-2 bg-gray-200">
                <input
                  className="bg-white appearance-none border-2 border-gray-300 rounded w-full py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  id="inline-full-name"
                  type="text"
                  defaultValue=""
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div className="flex space-x-2 my-3  ml-5">
      <button className="bg-gray-200 px-4 py-2 rounded-md">Add/Edit</button>
      <button className="bg-gray-200 px-4 py-2 rounded-md text-gray-500">
        Save
      </button>
      <button className="bg-gray-200 px-4 py-2 rounded-md">Exit</button>
    </div>
  </div>
</>

    </div>
  )
}

export default Match
