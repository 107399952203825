import React from 'react'
import NavBar from '../components/NavBar'

const Register = () => {
  return (
    <div>
     
      {/*<header className="bg-white shadow">
    <div className="container px-4 py-1 ">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <a href="./login.html">
            <img
              src="./images/yellow gold logo.png"
              alt="YellowGoldPro Logo"
              className="w-5 h-5"
            />
          </a>
          <span className="text-sm font-normal text-black">
            YellowGoldPro 1.0 Admin - Days Remaining: 319 - 11-Apr-2022
          </span>
        </div>
        {/*<div class="text-sm text-gray-600">
                Admin - Days Remaining: 319 - 11-Apr-2022
            </div>*
      </div>
    </div>
  </header>
  {/* Tabs */}
  {/*<nav className="bg-gray-200 shadow-inner">
    <div className="container px-4 h-10">
      <ul className="flex space-x-4 py-2">
        <div>
          <div className="relative inline-block text-left">
            <div>
              <button
                type="button"
                id="dropdownButton"
                className="text-gray-800 hover:text-blue-600 underline text-sm"
              >
                Master
              </button>
            </div>
            <div
              id="dropdownMenu"
              className="hidden origin-top-right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100"
            >
              <div className="py-1" role="none">
                <a
                  href="#"
                  className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                >
                  Registration
                </a>
                <a
                  href="./client.html"
                  className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                >
                  Client
                </a>
              </div>
              <div className="py-1" role="none">
                <a
                  href="./match.html"
                  className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                >
                  Match
                </a>
                <a
                  href="./session.html"
                  className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                >
                  Session
                </a>
              </div>
              <div className="py-1" role="none">
                <a
                  href="#"
                  className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                >
                  Online Client
                </a>
              </div>
              <div className="py-1" role="none">
                <a
                  href="#"
                  className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                >
                  System User
                </a>
              </div>
              <div className="py-1" role="none">
                <a
                  href="#"
                  className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                >
                  Exit
                </a>
              </div>
            </div>
          </div>
        </div>
        <li>
          <a
            href="./sessiontransaction.html"
            className="text-gray-800 hover:text-blue-600 underline text-sm"
          >
            Transaction
          </a>
        </li>
        <li>
          <a
            href="./compact.html"
            className="text-gray-800 hover:text-blue-600 underline text-sm"
          >
            Compact (F6)
          </a>
        </li>
        <li>
          <a
            href="./groupwise.html"
            className="text-gray-800 hover:text-blue-600 underline text-sm"
          >
            Trial Balance (F8)
          </a>
        </li>
        <li>
          <a
            href="./sattlement.html"
            className="text-gray-800 hover:text-blue-600 underline text-sm"
          >
            Settlement
          </a>
        </li>
        <li>
          <a
            href="./clientpopup.html"
            className="text-gray-800 hover:text-blue-600 underline text-sm"
          >
            Utilities
          </a>
        </li>
      </ul>
    </div>
  </nav>*/}
  <NavBar/>
  <div className="container mt-5 flex gap-4">
    <div className="w-[100%]">
      <form className="w-[100%]">
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-black font-normal md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="inline-full-name"
            >
              ID
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-white appearance-none border-2 border-gray-300 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="inline-full-name"
              type="text"
              defaultValue={1}
            />
          </div>
        </div>
        <div className="md:flex md:items-center mb-6 gap-2">
          <div className="md:w-1/3">
            <label
              className="block text-black font-normal md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="inline-full-name"
            >
              M NO.
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-1 px-4 text-black leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="inline-full-name"
              type="text"
              defaultValue="54EE752BFDAE"
            />
          </div>
          <div className="">
            <button
              className="bg-gray-300 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-black font-normal py-1 px-4 rounded"
              type="button"
            >
              C
            </button>
          </div>
        </div>
        <div className="md:flex md:items-center mb-6 gap-2">
          <div className="md:w-1/3">
            <label
              className="block text-black font-normal md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="inline-full-name"
            >
              P NO.
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-1 px-4 text-black leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="inline-full-name"
              type="text"
              defaultValue="Intel(R) Core(TM) i5-4210U CPU @ 1.70GHz"
            />
          </div>
          <div className="">
            <button
              className="bg-gray-300 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-black font-normal py-1 px-4 rounded"
              type="button"
            >
              C
            </button>
          </div>
        </div>
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-black font-normal md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="inline-full-name"
            >
              Name
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-white appearance-none border-2 border-gray-200 rounded w-full py-1 px-4 text-black leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="inline-full-name"
              type="text"
              defaultValue="K & K"
            />
          </div>
        </div>
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-black font-normal md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="inline-full-name"
            >
              Email
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-white appearance-none border-2 border-gray-200 rounded w-full py-1 px-4 text-black leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="inline-full-name"
              type="email"
              defaultValue="k@test.com"
            />
          </div>
        </div>
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-black font-normal md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="inline-full-name"
            >
              Cell No.
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-white appearance-none border-2 border-gray-200 rounded w-full py-1 px-8 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="inline-full-name"
              type="text"
              defaultValue=""
            />
          </div>
        </div>
        <div className="md:flex md:items-center">
          <div className="md:w-1/3" />
          <div className="md:w-2/3 gap-3 flex ">
            <button
              className="shadow bg-gray-300 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-black font-normal py-1 px-4 rounded"
              type="button"
            >
              Save
            </button>
            <button
              className="shadow bg-gray-300 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-black font-normal py-1 px-4 rounded"
              type="button"
            >
              Exit
            </button>
          </div>
        </div>
      </form>
    </div>
    <div className="w-full">
      <div className="bg-gray-200 p-2">
        <h2 className="text-gray-400">Licence Activation</h2>
      </div>
      <div className="bg-gray-200 w-[100%] p-4">
        <div className="md:flex md:items-center mb-6 ">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-normal md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="inline-full-name"
            >
              Activation Date
            </label>
          </div>
          <div className="mt-2">
            <input
              type="date"
              className="w-25 px-2 py-1 border border-gray-300 rounded-md bg-gray-200 text-gray-400"
            />
          </div>
          {/*<div class="md:w-2/3">
        <input
          class="bg-gray-200 appearance-none border-2 border-gray-300 rounded w-full py-1 px-2 text-gray-500 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
          id="inline-full-name"
          type="text"
          value="1"
        />
      </div>*/}
        </div>
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-normal md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="inline-full-name"
            >
              Total Days
            </label>
          </div>
          <div className="md:w-1/3 ">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-300 rounded w-25 py-1 px-1 text-gray-400
           leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="inline-full-name"
              type="text"
              defaultValue={366}
            />
          </div>
          <div>
            <h2 className="text-red-500">17-08-2025</h2>
          </div>
        </div>
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-normal md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="inline-full-name"
            >
              Serial Key
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-300 
          rounded w-full py-1 px-4 text-gray-400 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="inline-full-name"
              type="text"
              defaultValue="Intel(R) Core(TM) i5-4210U CPU @ 1.70GHz"
            />
          </div>
        </div>
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-normal md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="inline-full-name"
            >
              Status
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-300 rounded w-full py-1 px-4 text-gray-400 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="inline-full-name"
              type="text"
              defaultValue="Active"
            />
          </div>
        </div>
        <div className="md:flex md:items-center">
          <div className="md:w-1/3" />
          <div className="md:w-2/3">
            <button
              className="shadow bg-gray-300 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-gray-400 font-normal py-1 px-4 rounded"
              type="button"
            >
              Active
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
  )
}

export default Register
