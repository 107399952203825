import React from 'react'
import NavBar from '../components/NavBar'

const OnlineAccount = () => {
  return (
    <div>
        <NavBar/>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
  {/* Left Sidebar */}
  <div className="col-span-1 lg:col-span-1 bg-white p-4 rounded-md shadow-md">
    <h3 className="text-lg font-semibold mb-2">Party Trial</h3>
    <label className="block flex gap-4">
      <span className="text-black font-semibold text-m ">Exchange</span>
      <form className="flex items-center">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" />
          <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-8 py-1 pr-10 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
            <option />
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </form>
    </label>
    <div className="bg-gray-100 p-2 rounded-md mb-2">
      <div className="flex space-x-2">
        <input
          type="text"
          placeholder="Find"
          className="w-full px-2 py-1 border border-gray-300 rounded-md"
        />
        <button className="text-sm text-white bg-blue-500 px-4 py-1 rounded-md">
          Find
        </button>
        <button className="text-sm text-white bg-blue-500 px-4 py-1 rounded-md">
          Clear
        </button>
      </div>
    </div>
    <table className="min-w-full border border-gray-300 mb-4">
      <thead className="bg-gray-200">
        <tr>
          <th className="border px-4 py-2 text-left">AC Name</th>
          <th className="border px-4 py-2 text-right">Current Balance</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="border px-4 py-2">ABHINAV</td>
          <td className="border px-4 py-2 text-right">0.00</td>
        </tr>
        <tr>
          <td className="border px-4 py-2">BHANU</td>
          <td className="border px-4 py-2 text-right">-300.00</td>
        </tr>
        <tr>
          <td className="border px-4 py-2">CASH</td>
          <td className="border px-4 py-2 text-right">700.00</td>
        </tr>
        <tr>
          <td className="border px-4 py-2">LOKESH JI</td>
          <td className="border px-4 py-2 text-right text-red-600">-9628.83</td>
        </tr>
        <tr>
          <td className="border px-4 py-2">LOKESH JI</td>
          <td className="border px-4 py-2 text-right text-red-600">-9628.83</td>
        </tr>
        <tr>
          <td className="border px-4 py-2">LOKESH JI</td>
          <td className="border px-4 py-2 text-right text-red-600">-9628.83</td>
        </tr>
        {/* More rows as needed */}
      </tbody>
    </table>
    <div className="flex justify-between">
      <div className="">(+)Total</div>
      <div className="space-y-1 text-right">
        <div className="border border-gray-300 p-2">65060.30</div>
        <div className="flex justify-between text-sm text-red-600 border border-gray-300 p-2">
          <span>-10248.83</span>
        </div>
      </div>
    </div>
    <div className="flex justify-between mt-4">
      <button className="bg-gray-200 text-black px-2 py-2 rounded-md">
        Print Net Result
      </button>
      <button className="bg-gray-200 text-black px-2 py-2 rounded-md">
        Print Group Result
      </button>
    </div>
  </div>
  {/* Main Table and Details */}
  <div className="col-span-1 lg:col-span-3 bg-white p-3 rounded-md shadow-md">
    <div className="flex justify-between mb-4">
      <div className="flex gap-1">
        <span className="block text-m font-normal">Old Balance</span>
        <input
          className="shadow appearance-none border rounded w-32 py-0 text-gray-700 
        leading-tight focus:outline-none focus:shadow-outline"
          id=""
          type="text"
          placeholder="-7161.33"
        />
        {/*<span class="block text-red-600 text-2xl">-7161.33</span>*/}
      </div>
      <div className="flex-1 text-center">
        {/*<span class="block text-xl font-semibold">LOKESH JI</span>*/}
        <input
          className="shadow appearance-none border rounded w-64 py-1 text-black 
        leading-tight focus:outline-none focus:shadow-outline text-center"
          id="text"
          type="text"
          placeholder="LOKESH JI"
        />
      </div>
      <div className="flex gap-2">
        {/*<span class="block text-lg font-semibold">Current Balance</span>*/}
        <label
          className="block text-gray-700 text-lg font-normal mb-2"
          htmlFor="username"
        >
          Current Balance
        </label>
        <input
          className="shadow appearance-none border rounded w-32 py-1 text-red-500 
          leading-tight focus:outline-none focus:shadow-outline"
          id=""
          type="text"
          placeholder="-9628.83"
        />
        {/*<span class="block text-red-600 text-2xl">-9628.83</span>*/}
      </div>
      <div className="flex gap-2">
        {/*<span class="block text-lg font-semibold">Current Balance</span>*/}
        <label
          className="block text-gray-700 text-m font-normal mb-2"
          htmlFor="username"
        >
          P &amp; L Acc
        </label>
        <input
          className="shadow appearance-none border rounded w-32 py-1 text-red-500 leading-tight focus:outline-none focus:shadow-outline"
          id=""
          type="text"
          placeholder="-9628.83"
        />
        {/*<span class="block text-red-600 text-2xl">-9628.83</span>*/}
      </div>
    </div>
    <div className="flex gap-4">
      <div className="col-span-1">
        <h4 className="text-md font-semibold">Details</h4>
        <table className="min-w-full border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="border px-4 py-2 text-left">Date</th>
              <th className="border px-4 py-2 text-left">Name</th>
              <th className="border px-4 py-2 text-left">Reference</th>
              <th className="border px-4 py-2 text-right">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-4 py-2">11/03/2022 07:23 PM</td>
              <td className="border px-4 py-2">CASH</td>
              <td className="border px-4 py-2">First Comm</td>
              <td className="border px-4 py-2 text-right text-red-600">
                -300.00
              </td>
            </tr>
            {/* More rows as needed */}
          </tbody>
        </table>
        <div className="flex justify-start mt-4 space-x-2">
          <button className="bg-gray-200 text-black px-4 py-2 rounded-md">
            Delete
          </button>
          <button className="bg-gray-200 text-black px-4 py-2 rounded-md">
            Edit
          </button>
        </div>
      </div>
      <div className="col-span-1">
        <h4 className="text-md font-semibold">Details</h4>
        <table className="min-w-full border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="border px-4 py-2 text-left">Date</th>
              <th className="border px-4 py-2 text-left">Name</th>
              <th className="border px-4 py-2 text-left">Reference</th>
              <th className="border px-4 py-2 text-right">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-4 py-2">11/03/2022 07:23 PM</td>
              <td className="border px-4 py-2">CASH</td>
              <td className="border px-4 py-2">First Comm</td>
              <td className="border px-4 py-2 text-right text-red-600">
                -300.00
              </td>
            </tr>
            {/* More rows as needed */}
          </tbody>
        </table>
        <div className="flex justify-end mt-4 space-x-2">
          <button className="bg-gray-200 text-black px-4 py-2 rounded-md">
            Delete
          </button>
          <button className="bg-gray-200 text-black px-4 py-2 rounded-md">
            Edit
          </button>
        </div>
      </div>
    </div>
    <div className="mt-5">
      <h4 className="text-lg font-bold">Transaction</h4>
      <div className="grid grid-cols-6 gap-2 mt-2 flex">
        <input
          type="text"
          placeholder="Balance"
          className="px-1 py-0 border border-gray-300 rounded-md"
        />
        <input
          type="text"
          placeholder="Client"
          className="px-1 py-0 border border-gray-300 rounded-md"
        />
        <input
          type="text"
          placeholder="Amount"
          className="px-1 py-0 border border-gray-300 rounded-md"
        />
        <input
          type="text"
          placeholder="Narration"
          className="px-1 py-0 border border-gray-300 rounded-md"
        />
        <div className="flex mt-4 space-x-2">
          <button className="bg-blue-500 text-white px-4 py-2 rounded-md">
            Save
          </button>
          <button className="bg-gray-200 text-black px-4 py-2 rounded-md">
            Print
          </button>
          <button className="bg-gray-200 text-black px-4 py-2 rounded-md">
            Exit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default OnlineAccount
