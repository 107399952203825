import React from 'react'
import NavBar from '../components/NavBar'

const Session = () => {
  return (
    <div>
      <>
      <NavBar/>
  <div className="container mx-auto bg-white p-4 rounded-lg shadow-lg mt-5">
    {/* Header */}
    {/* Table */}
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border rounded-lg">
        <thead>
          <tr className="bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <th className="p-2">Code</th>
            <th className="p-2">Date</th>
            <th className="p-2">Session Comment</th>
            <th className="p-2">Result</th>
            <th className="p-2">Run</th>
            <th className="p-2">Comm.</th>
            <th className="p-2">Active</th>
            <th className="p-2">Match</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {/* Add your data rows here */}
          <tr className="bg-white">
            <td className="p-2">4</td>
            <td className="p-2">4/4/2022</td>
            <td className="p-2">6 lkw</td>
            <td className="p-2">Declared</td>
            <td className="p-2">40</td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2">IND VS PAK</td>
          </tr>
          <tr className="bg-white">
            <td className="p-2">3</td>
            <td className="p-2">3/24/2022</td>
            <td className="p-2">6</td>
            <td className="p-2">Declared</td>
            <td className="p-2">80</td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2">IND VS PAK</td>
          </tr>
          <tr className="bg-white">
            <td className="p-2">2</td>
            <td className="p-2">3/11/2022</td>
            <td className="p-2">10 ovr</td>
            <td className="p-2">Declared</td>
            <td className="p-2">4000</td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2">IND VS LANKA</td>
          </tr>
          <tr className="bg-white">
            <td className="p-2">1</td>
            <td className="p-2">3/4/2022</td>
            <td className="p-2">6 ovr</td>
            <td className="p-2">Undeclared</td>
            <td className="p-2">40</td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2">IND VS LANKA</td>
          </tr>
          <tr className="bg-white">
            <td className="p-2">1</td>
            <td className="p-2">3/4/2022</td>
            <td className="p-2">6 ovr</td>
            <td className="p-2">Undeclared</td>
            <td className="p-2">40</td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2">IND VS LANKA</td>
          </tr>

          <tr className="bg-white">
            <td className="p-2">1</td>
            <td className="p-2">3/4/2022</td>
            <td className="p-2">6 ovr</td>
            <td className="p-2">Undeclared</td>
            <td className="p-2">40</td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2">IND VS LANKA</td>
          </tr>

          <tr className="bg-white">
            <td className="p-2">1</td>
            <td className="p-2">3/4/2022</td>
            <td className="p-2">6 ovr</td>
            <td className="p-2">Undeclared</td>
            <td className="p-2">40</td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2">IND VS LANKA</td>
          </tr>

          <tr className="bg-white">
            <td className="p-2">1</td>
            <td className="p-2">3/4/2022</td>
            <td className="p-2">6 ovr</td>
            <td className="p-2">Undeclared</td>
            <td className="p-2">40</td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2">IND VS LANKA</td>
          </tr>

          <tr className="bg-white">
            <td className="p-2">1</td>
            <td className="p-2">3/4/2022</td>
            <td className="p-2">6 ovr</td>
            <td className="p-2">Undeclared</td>
            <td className="p-2">40</td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2">IND VS LANKA</td>
          </tr>
          <tr className="bg-white">
            <td className="p-2">1</td>
            <td className="p-2">3/4/2022</td>
            <td className="p-2">6 ovr</td>
            <td className="p-2">Undeclared</td>
            <td className="p-2">40</td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2 text-center">
              <input
                type="checkbox"
                className="form-checkbox"
                defaultChecked=""
              />
            </td>
            <td className="p-2">IND VS LANKA</td>
          </tr>
          {/* Add more rows as needed */}
        </tbody>
      </table>
    </div>
  </div>
  <div className="flex space-x-2 mt-5 ml-5">
    <button className="bg-gray-200 px-4 py-2 rounded-md">Add/Edit</button>
    <button className="bg-gray-200 px-4 py-2 rounded-md">Save</button>
    <button className="bg-gray-200 px-4 py-2 rounded-md">Exit</button>
  </div>
</>

    </div>
  )
}

export default Session
