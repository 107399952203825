import React from "react";
import NavBar from "../components/NavBar";
import { useEffect } from "react";


const Compact = () => {
  return (
    <div>
      <NavBar />
      <div className="container mx-auto bg-white p-3 rounded-lg ">
        <div className="flex">
          <div className="overflow-x-auto w-[20%] border border-gray-300">
            <div className="">
              <h4 className="font-bold text-lg bg-gray-300 p-1">Yes-L</h4>
            </div>
            <div className="flex bg-gray-200">
              <h4 className="p-1"> From</h4>
              <div className="md:w-1/2 m-1">
                <input
                  className="bg-white appearance-none border-2 border-gray-300 rounded w-14 py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  id="inline-full-name"
                  type="text"
                  defaultValue=""
                />
              </div>

              <h4 className="p-1">Upto</h4>
              <div className="md:w-1/2 m-1">
                <input
                  className="bg-white appearance-none border-2 border-gray-300 rounded w-14 py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  id="inline-full-name"
                  type="text"
                  defaultValue=""
                />
              </div>
            </div>

            <table className="min-w-full bg-white  rounded-lg">
              <thead className="divide-x">
                <tr
                  className="bg-gray-100 text-left 
                text-m font-normal text-black tracking-wider "
                >
                  <th className="p-2">Run</th>
                  <th className="p-2">Amount</th>
                </tr>
              </thead>
              <tbody className="divide-y divide divide-gray-200">
                {/* Add your data rows here */}
                <tr className="divide-x">
                  <td className="p-2">44</td>
                  <td className="p-2">-4545</td>
                </tr>
                <tr className="divide-x">
                  <td className="p-2">45</td>
                  <td className="p-2">-4545</td>
                </tr>
                <tr className="divide-x">
                  <td className="p-2">46</td>
                  <td className="p-2">-4545</td>
                </tr>
                <tr className="divide-x">
                  <td className="p-2">47</td>
                  <td className="p-2">-1545</td>
                </tr>
                <tr className="divide-x">
                  <td className="p-2">48</td>
                  <td className="p-2">-1545</td>
                </tr>
                <tr className="divide-x">
                  <td className="p-2">49</td>
                  <td className="p-2">-1545</td>
                </tr>
                <tr className="divide-x">
                  <td className="p-2">50</td>
                  <td className="p-2">MUM VS KOL</td>
                </tr>
                <tr className="divide-x">
                  <td className="p-2">51</td>
                  <td className="p-2">-1545</td>
                </tr>{" "}
                <tr className="divide-x">
                  <td className="p-2">52</td>
                  <td className="p-2">MUM VS KOL</td>
                </tr>
                <tr className="divide-x">
                  <td className="p-2">53</td>
                  <td className="p-2">-1545</td>
                </tr>
                <tr className="divide-x">
                  <td className="p-2">54</td>
                  <td className="p-2">4455</td>
                </tr>
                <tr className="divide-x">
                  <td className="p-2">55</td>
                  <td className="p-2">4455</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="overflow-x-auto w-[80%]  ">
            <div>
              <div className="container mx-auto bg-gray-200 p-0 border border-gray-300 ">
                <div className="flex gap-x-56 bg-gray-300 border border-gray-300">
                  <h2 className="font-bold text-m p-1">Main book </h2>
                  <div className=" ">
                    <button className="bg-gray-200 px-4 py-0 rounded-md m-1 border border-gray-400 text-black">
                      More>
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-2  gap-4 flex border border-gray-300 ">
                  <div className="flex border border-gray-300 m-1 w-96">
                    <div className="p-4  shadow-sm bg-gray-200 ">
                      <div className="flex justify-between items-center ">
                        <div className="md:w-1/3">
                          <label
                            className="block text-black font-bold md:text-right mb-1 md:mb-0 pr-4"
                            htmlFor="inline-full-name"
                          >
                            Team1
                          </label>
                        </div>
                        <div className="md:w-2/3">
                          <input
                            className="bg-white appearance-none border-2 border-gray-300 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            id="inline-full-name"
                            type="text"
                            defaultValue=""
                          />
                        </div>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="md:w-1/3">
                          <label
                            className="block text-black font-bold md:text-right mb-1 md:mb-0 pr-4"
                            htmlFor="inline-full-name"
                          >
                            Team2
                          </label>
                        </div>
                        <div className="md:w-2/3">
                          <input
                            className="bg-white appearance-none border-2 border-gray-300 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            id="inline-full-name"
                            type="text"
                            defaultValue=""
                          />
                        </div>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="md:w-1/3">
                          <label
                            className="block text-black font-bold md:text-right mb-1 md:mb-0 pr-4"
                            htmlFor="inline-full-name"
                          >
                            Team3
                          </label>
                        </div>
                        <div className="md:w-2/3">
                          <input
                            className="bg-white appearance-none border-2 border-gray-300 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            id="inline-full-name"
                            type="text"
                            defaultValue=""
                          />
                        </div>
                      </div>
                    </div>

                    <div className="bg-gray-200 p-4  shadow-sm">
                      <div className="flex justify-between items-center">
                        <div className="md:w-1/3">
                          <label
                            className="block text-black font-bold md:text-right mb-1 md:mb-0 pr-4"
                            htmlFor="inline-full-name"
                          >
                            Avg
                          </label>
                        </div>
                        <div className="md:w-2/3">
                          <input
                            className="bg-white appearance-none border-2 border-gray-300 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            id="inline-full-name"
                            type="text"
                            defaultValue=""
                          />
                        </div>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="md:w-1/3">
                          <label
                            className="block text-black font-bold md:text-right mb-1 md:mb-0 pr-4"
                            htmlFor="inline-full-name"
                          >
                            Avg
                          </label>
                        </div>
                        <div className="md:w-2/3">
                          <input
                            className="bg-white appearance-none border-2 border-gray-300 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            id="inline-full-name"
                            type="text"
                            defaultValue=""
                          />
                        </div>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="md:w-1/3">
                          <label
                            className="block text-black font-bold md:text-right mb-1 md:mb-0 pr-4"
                            htmlFor="inline-full-name"
                          >
                            Avg
                          </label>
                        </div>
                        <div className="md:w-2/3">
                          <input
                            className="bg-white appearance-none border-2 border-gray-300 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            id="inline-full-name"
                            type="text"
                            defaultValue=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="    m-1">
                    <div className="text-center">
                      <button className="bg-gray-200 px-4 py-0 rounded-md m-1  text-black">
                        Find (Ctrl+F)
                      </button>
                    </div>
                    <div className="flex  px-44">
                      <div className="text-center">
                        <button className="bg-gray-200 px-4 py-0 rounded-md m-1 border border-gray-400  text-black">
                          Exit
                        </button>
                      </div>

                      <div className="text-center">
                        <button className="bg-gray-200 px-4 py-0 rounded-md m-1 border border-gray-400  text-black ">
                          Delete
                        </button>
                      </div>
                    </div>

                    <div className="flex  px-44">
                      <div className="text-center">
                        <button className="bg-gray-200 px-4 py-0 rounded-md m-1 border border-gray-400  text-black">
                          Refresh
                        </button>
                      </div>

                      <div className="">
                        <button className="bg-gray-200 px-4 py-0 w-36 rounded-md m-1 border border-gray-400  text-black">
                          Show Deleted
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-gray-50 p-2  border border-gray-300">
                <div className="flex justify-between items-center ">
                  <div className="flex">
                    Match Name
                    <form className="flex items-center">
                      <label htmlFor="search" className="sr-only">
                        Search
                      </label>
                      <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" />
                        <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-8 py-1 pr-10 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                          <option />
                          <option>Option 2</option>
                          <option>Option 3</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div>Date: 04/03/2022</div>
                  {/*<div class="flex items-center space-x-4">*/}
                  <label className="flex items-center space-x-2">
                    <input type="checkbox" className="form-checkbox" />
                    <span>Time</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input type="checkbox" className="form-checkbox" />
                    <span>Dabba</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input type="checkbox" className="form-checkbox" />
                    <span>Patti</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input type="checkbox" className="form-checkbox" />
                    <span>Calculate Patti</span>
                  </label>
                </div>
              </div>
              <div className="overflow-x-auto ">
                <table className="min-w-full bg-white border border-gray-300">
                  <thead>
                    <tr className="bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <th className="p-2">Sr</th>
                      <th className="p-2">Rate</th>
                      <th className="p-2">Amount</th>
                      <th className="p-2">Mode</th>
                      <th className="p-2">Team</th>
                      <th className="p-2">Client Name</th>
                      <th className="p-2">INDIA</th>
                      <th className="p-2">LANKA</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {/* Add your data rows here */}
                    <tr>
                      <td className="p-2">6</td>
                      <td className="p-2">0.3400</td>
                      <td className="p-2">10000.00</td>
                      <td className="p-2">K</td>
                      <td className="p-2">INDIA</td>
                      <td className="p-2">SANWARIYA</td>
                      <td className="p-2 text-red-500">-3400.00</td>
                      <td className="p-2 text-blue-500">10000.00</td>
                    </tr>
                    {/* Add more rows as needed */}
                  </tbody>
                </table>
              </div>

              {/*next section*/}
              <div className="flex space-x-2 ml-auto p-1 text-left border border-gray-300">
                <div className="mt-7 flex">
                  <button className="bg-gray-200 px-4 py-0 border border-gray-300 ">
                    Edit
                  </button>
                </div>

                <div>
                  <label className="block text-gray-800">Rate</label>
                  <input type="text" className="w-20 p-0 mt-1 border " />
                </div>

                <div>
                  <label className="block text-gray-700">Amount</label>
                  <input type="text" className="w-20 p-0 mt-1 border" />
                </div>
                <div>
                  <label className="block text-gray-700">Mode</label>
                  <form className="flex items-center">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative w-full">
                      <div className="absolute inset-y-0 left-0 flex items-center  pointer-events-none" />
                      <select className="block appearance-none w-20 bg-white border border-gray-400 hover:border-gray-500 px-4 py-1  rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                        <option />
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </form>
                </div>


                <div>
                  <label className="block text-gray-700">Support Team</label>
                  <form className="flex items-center">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative w-full">
                      <div className="absolute inset-y-0 left-0 flex items-center  pointer-events-none" />
                      <select className="block appearance-none w-24 bg-white border border-gray-400 hover:border-gray-500 px-4 py-1  rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                        <option />
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </form>
                </div>
                {/*<div>
                  <label className="block text-gray-700">
                    Support Team
                    <form className="flex items-center">
                      <label htmlFor="search" className="sr-only">
                        Search
                      </label>
                      <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" />
                        <select className="block appearance-none w-20 bg-white border border-gray-400
                         hover:border-gray-500 px-8 py-1 pr-10 rounded shadow leading-tight 
                         focus:outline-none focus:shadow-outline">
                          <option />
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </form>
                  </label>
                </div>*/}
                <div>
                  <label className="block text-gray-700">Client Name</label>
                  <input type="text" className="w-20 p-0 mt-1 border " />
                </div>
                <div className="mt-7 gap-2 flex">
                  <button className="bg-gray-200 px-4 py-0 border border-gray-300 ">
                    Save
                  </button>
                </div>

                <div>
                  <label className="block text-gray-800">Rate</label>
                  <input type="text" className="w-20 p-0 mt-1 border " />
                </div>
                <div>
                  <label className="block text-gray-700">Run</label>
                  <input type="text" className="w-20 p-0 mt-1 border " />
                </div>
                <div>
                  <label className="block text-gray-700">Amount</label>
                  <input type="text" className="w-20 p-0 mt-1 border" />
                </div>

                <div className="mt-7 gap-2 flex">
                  <button className="bg-gray-200 px-4 py-0 border border-gray-300 ">
                    Watch List
                  </button>
                </div>
              </div>

              {/*end*/}

              <div className="">
                {/*-start*/}
                <div className="bg-gray-50 p-1 border border-gray-300 ">
                  <div className="flex justify-between items-center ">
                    <div className="flex">
                      Session
                      <form className="flex items-center">
                        <label htmlFor="search" className="sr-only">
                          Search
                        </label>
                        <div className="relative w-full">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" />
                          <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-8 py-1 pr-10 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                            <option />
                            <option>Option 2</option>
                            <option>Option 3</option>
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg
                              className="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div>Date: 04/03/2022</div>
                    {/*<div class="flex items-center space-x-4">*/}
                    <label className="flex items-center space-x-2">
                      <input type="checkbox" className="form-checkbox" />
                      <span>Time</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input type="checkbox" className="form-checkbox" />
                      <span>Dabba</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input type="checkbox" className="form-checkbox" />
                      <span>Patti</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input type="checkbox" className="form-checkbox" />
                      <span>Calculate Patti</span>
                    </label>
                  </div>
                </div>
                {/*----------end*/}
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                      <tr className="bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <th className="p-2">Sr #</th>
                        <th className="p-2">Client Name</th>
                        <th className="p-2">Run</th>
                        <th className="p-2">Rate</th>
                        <th className="p-2">Amount</th>
                        <th className="p-2">Mode</th>
                        <th className="p-2">Yes (Pass)</th>
                        <th className="p-2">No (Fail)</th>
                        <th className="p-2">User Comm</th>
                        <th className="p-2">Reff Comm</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {/* Add your data rows here */}
                      <tr>
                        <td className="p-2">9</td>
                        <td className="p-2">SANWARIYA</td>
                        <td className="p-2">47</td>
                        <td className="p-2">1.0000</td>
                        <td className="p-2">2000.00</td>
                        <td className="p-2">Yes</td>
                        <td className="p-2">1940.00</td>
                        <td className="p-2">-2060.00</td>
                        <td className="p-2">-60.00</td>
                        <td className="p-2">0.00</td>
                      </tr>
                      <tr className="">
                        <td className="p-2">8</td>
                        <td className="p-2">NAVNEET</td>
                        <td className="p-2">54</td>
                        <td className="p-2">1.0000</td>
                        <td className="p-2">3000.00</td>
                        <td className="p-2">Yes</td>
                        <td className="p-2">3000.00</td>
                        <td className="p-2">-3000.00</td>
                        <td className="p-2">0.00</td>
                        <td className="p-2">0.00</td>
                      </tr>
                      {/* Add more rows as needed */}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex gap-72 border border-gray-300 p-2 bg-gray-200">
                <div className="w-10">
                  <input
                    className="bg-white appearance-none border border-gray-300 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-full-name"
                    type="text"
                    defaultValue=""
                  />
                </div>
                <div className="w-20">
                  <input
                    className="bg-white appearance-none border border-gray-300 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-full-name"
                    type="text"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>
            <div className="flex space-x-2 ml-auto p-1 text-left border border-gray-300">
              <div className="mt-7 flex">
                <button className="bg-gray-200 px-4 py-0 border border-gray-300 ">
                  Save
                </button>
              </div>

              <div>
                <label className="block text-gray-800">Rate</label>
                <input type="text" className="w-20 p-0 mt-1 border " />
              </div>
              <div>
                <label className="block text-gray-700">Run</label>
                <input type="text" className="w-20 p-0 mt-1 border " />
              </div>
              <div>
                <label className="block text-gray-700">Amount</label>
                <input type="text" className="w-50 p-0 mt-1 border" />
              </div>
              <div>
                <label className="block text-gray-700">Mode</label>
                <form className="flex items-center">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" />
                    <select className="block appearance-none w-20 bg-white border border-gray-400 hover:border-gray-500 px-4 py-1 pr-10 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                      <option />
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </form>
              </div>
              <div>
                <div id="app"></div>
                <label className="block text-gray-700">Client Name</label>
                <input type="text" className="w-64 p-0 mt-1 border " />
              </div>
              <div className="mt-1 gap-1 flex">
                <div className="">
                  <input type="checkbox" className="form-checkbox" />
                  <span>Comm. Y/N</span>
                  <button className="bg-gray-200 px-4 py-0 border border-gray-300 ">
                   
                  </button>
                </div>
                <div className="mt-6">
                  <button className="bg-gray-200 px-4 py-0 border border-gray-300 ">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compact;
