import React from "react";
import NavBar from "../components/NavBar";

const SessionTransaction = () => {
  return (
    <div>
      <NavBar />
      <div className="container mx-auto bg-white p-6 rounded-lg ">
        <div className="flex ">
          <div className="overflow-x-auto w-[20%] border border-gray-400 ">
            <div className="border border-gray-400">
              <h4 className="font-bold text-lg bg-gray-300 p-1">Yes-L</h4>
            </div>
            <div className="flex bg-gray-200 border border-gray-400">
              <h4 className="p-1"> From</h4>
              <div className="md:w-1/2 m-1">
                <input
                  className="bg-white appearance-none border border-gray-400 rounded w-14 py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  id="inline-full-name"
                  type="text"
                  defaultValue=""
                />
              </div>

              <h4 className="p-1">Upto</h4>
              <div className="md:w-1/2 m-1 ">
                <input
                  className="bg-white appearance-none border border-gray-400 rounded w-14 py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  id="inline-full-name"
                  type="text"
                  defaultValue=""
                />
              </div>
            </div>

            <table className="min-w-full bg-white rounded-lg  ">
              <thead className="divide-x">
                <tr className="bg-gray-100 text-left text-m font-normal text-black tracking-wider">
                  <th className="p-2">Run</th>
                  <th className="p-2">Amount</th>
                </tr>
              </thead>

              <tbody className="divide-y divide divide-gray-200">
                {/* Add your data rows here */}
                <tr className="divide-x">
                  <td className="p-2">44</td>
                  <td className="p-2">-4545</td>
                </tr>
                <tr className="divide-x">
                  <td className="p-2">45</td>
                  <td className="p-2">-4545</td>
                </tr>
                <tr className="divide-x">
                  <td className="p-2">46</td>
                  <td className="p-2">-4545</td>
                </tr>
                <tr className="divide-x">
                  <td className="p-2">47</td>
                  <td className="p-2">-1545</td>
                </tr>
                <tr className="divide-x">
                  <td className="p-2">48</td>
                  <td className="p-2">-1545</td>
                </tr>
                <tr className="divide-x">
                  <td className="p-2">49</td>
                  <td className="p-2">-1545</td>
                </tr>
                <tr className="divide-x">
                  <td className="p-2">50</td>
                  <td className="p-2">MUM VS KOL</td>
                </tr>
                <tr className="divide-x">
                  <td className="p-2">51</td>
                  <td className="p-2">-1545</td>
                </tr>{" "}
              </tbody>
            </table>
          </div>
          <div className="overflow-x-auto w-[80%] ">
            <div className="container mx-auto bg-white rounded-lg shadow-lg  ">
              <div className="flex justify-between items-center border border-gray-400    bg-gray-200">
                <h2 className="text-lg font-semibold mb-2 p-1">
                  Select Session
                </h2>
                <h2 className="text-lg p-3">Date: 04/03/2022</h2>
                {/*<button class="bg-gray-200 px-4 py-2 rounded-md">Exit</button>*/}
              </div>
              <div className="bg-gray-50  p-1 shadow-sm mt-1 border border-gray-400 ">
                <div className="flex justify-between items-center mb-2 ">
                  <label className="block flex gap-2">
                    <span className="text-black font-semibold text-m">
                      Session
                    </span>
                    <form className="flex items-center">
                      <label htmlFor="search" className="sr-only">
                        Search
                      </label>
                      <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" />
                        <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-8 py-1 pr-10 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                          <option />
                          <option>Option 2</option>
                          <option>Option 3</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </form>
                  </label>
                  <div>Match Name: IND VS LANKA</div>
                  <div>Date: 04/03/2022</div>
                  {/*<div class="flex items-center space-x-4">*/}
                  <label className="flex items-center space-x-2">
                    <input type="checkbox" className="form-checkbox" />
                    <span>Time</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input type="checkbox" className="form-checkbox" />
                    <span>Dabba</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input type="checkbox" className="form-checkbox" />
                    <span>Patti</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input type="checkbox" className="form-checkbox" />
                    <span>Calculate Patti</span>
                  </label>
                  <div>
                    <button className="bg-gray-200 px-4 py-1 border border-gray-400 rounded-md">
                      Exit
                    </button>
                  </div>
                </div>
                {/*start*/}
                <div className="flex justify-between items-center bg-gray-200 p-3 border border-gray-400 mt-2">
                  <div className="w-full max-w-md ml-2">
                    <form className="flex items-center">
                      <label htmlFor="search" className="sr-only">
                        Search
                      </label>
                      <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" />
                        <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-0 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                          <option />
                          <option>Option 2</option>
                          <option>Option 3</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="ml-2 text-black bg-gray-200 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 
      font-medium rounded-md text-sm px-4 py-0 border border-gray-600"
                      >
                        Find
                      </button>
                      <button
                        type="submit"
                        className="ml-2 text-black bg-gray-200 hover:bg-blue-700 focus:ring-4 focus:outline-none 
      focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-0 border border-gray-600"
                      >
                        Clear
                      </button>
                    </form>
                  </div>
                </div>
                {/*<div class="w-full max-w-md  ml-2">
          <form class="flex items-center">
            <label for="search" class="sr-only">Search</label>
            <div class="relative w-full">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-4.35-4.35m1.65-6.65a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="search"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                placeholder="Search..."
              />
            </div>
            <button
              type="submit"
              class="ml-2 text-black bg-gray-200 hover:bg-blue-700 focus:ring-4 focus:outline-none
               focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 border border-gray-600"
            >
              Find
            </button>
  
            <button
              type="submit"
              class="ml-2 text-black bg-gray-200 hover:bg-blue-700 focus:ring-4 
              focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 border border-gray-600"
            >
              Clear
            </button>
          </form>
        </div>*/}
                {/*-end*/}
              </div>
              {/*<div class="grid grid-cols-1 md:grid-cols-2 mb-4">
          <div class="bg-gray-50 p-4 rounded-lg shadow-sm">
              <h2 class="text-lg font-semibold mb-2 bg-gray-200">Select Session</h2>
              <div class="flex items-center space-x-2 mb-2">
                  <label class="block">
                      <span class="text-gray-700">Session</span>
                      <select class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm">
                          <option>6 ovr</option>
                          <!-- Add more options as needed */}
              {/*</select>
                  </label>
                  <button class="bg-gray-200 px-4 py-2 rounded-md">Find</button>
                  <button class="bg-gray-200 px-4 py-2 rounded-md">Clear</button>
              </div>
              <div class="flex items-center space-x-4">
                  <label class="flex items-center space-x-2">
                      <input type="checkbox" class="form-checkbox">
                      <span>Time</span>
                  </label>
                  <label class="flex items-center space-x-2">
                      <input type="checkbox" class="form-checkbox">
                      <span>Dabba</span>
                  </label>
                  <label class="flex items-center space-x-2">
                      <input type="checkbox" class="form-checkbox">
                      <span>Patti</span>
                  </label>
                  <label class="flex items-center space-x-2">
                      <input type="checkbox" class="form-checkbox">
                      <span>Calculate Patti</span>
                  </label>
              </div>
          </div>
          <div class="bg-gray-50 p-4 rounded-lg shadow-sm">
              <h2 class="text-lg font-semibold mb-2 bg-gray-200">Date: 04/03/2022</h2>
              <div class="flex justify-between items-center mb-2">
                  <div>Match: IND VS LANKA</div>
              </div>
          </div>
      </div>*/}
              {/*<div class="overflow-x-auto">
          <table class="min-w-full bg-white border rounded-lg">
              <thead>
                  <tr class="bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <th class="p-2">SR#</th>
                      <th class="p-2">Client Name</th>
                      <th class="p-2">Run</th>
                      <th class="p-2">Rate</th>
                      <th class="p-2">Amount</th>
                      <th class="p-2">Y/N</th> 
                      <th class="p-2">Yes(Pass)</th>
                      <th class="p-2">No(Fail)</th>
                      <th class="p-2">User commt.</th>
                      <th class="p-2">Reff.comm</th>
                  </tr>
              </thead>*/}
              {/*<tbody class="divide-y divide-gray-200">
                  <!-- Add your data rows here */}
              {/*<tr>
                      <td class="p-2">44</td>
                      <td class="p-2">-4545.00</td>
                  </tr>
                  <!-- Add more rows as needed */}
              {/*</tbody>
          </table>
      </div>*/}
              <div className="mt-4">
                {/*<h2 class="text-lg font-semibold mb-2">Client Transactions</h2>*/}
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white border border-gray-400 rounded-lg">
                    <thead>
                      <tr className="bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <th className="p-2">Sr #</th>
                        <th className="p-2">Client Name</th>
                        <th className="p-2">Run</th>
                        <th className="p-2">Rate</th>
                        <th className="p-2">Amount</th>
                        <th className="p-2">Y/N</th>
                        <th className="p-2">Yes (Pass)</th>
                        <th className="p-2">No (Fail)</th>
                        <th className="p-2">User Comm</th>
                        <th className="p-2">Reff Comm</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {/* Add your data rows here */}
                      <tr>
                        <td className="p-2">9</td>
                        <td className="p-2">SANWARIYA</td>
                        <td className="p-2">47</td>
                        <td className="p-2">1.0000</td>
                        <td className="p-2">2000.00</td>
                        <td className="p-2">Yes</td>
                        <td className="p-2">1940.00</td>
                        <td className="p-2">-2060.00</td>
                        <td className="p-2">-60.00</td>
                        <td className="p-2">0.00</td>
                      </tr>
                      <tr className="">
                        <td className="p-2">8</td>
                        <td className="p-2">NAVNEET</td>
                        <td className="p-2">54</td>
                        <td className="p-2">1.0000</td>
                        <td className="p-2">3000.00</td>
                        <td className="p-2">Yes</td>
                        <td className="p-2">3000.00</td>
                        <td className="p-2">-3000.00</td>
                        <td className="p-2">0.00</td>
                        <td className="p-2">0.00</td>
                      </tr>
                      {/* Add more rows as needed */}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex gap-72 border border-gray-400 p-2 bg-gray-200">
                <div className="w-10">
                  <input
                    className="bg-white appearance-none border border-gray-400 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-full-name"
                    type="text"
                    defaultValue=""
                  />
                </div>
                <div className="w-20">
                  <input
                    className="bg-white appearance-none border border-gray-400 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-full-name"
                    type="text"
                    defaultValue=""
                  />
                </div>
              </div>

              <div className="flex space-x-2 ml-auto text-left bg-gray-200 p-5 border border-gray-400 pb-9">
                <div className="mt-7 ">
                  <button className="bg-gray-200 px-4 py-0 rounded-md  border border-gray-600">
                    Edit
                  </button>
                </div>
                <div>
                  <label className="block text-gray-700">Rate</label>
                  <input
                    type="text"
                    className="w-20 py-0 mt-1 border border-gray-400 rounded-md px-4"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Run</label>
                  <input
                    type="text"
                    className="w-20 py-0 mt-1 border border-gray-400 rounded-md px-4"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Amount</label>
                  <input
                    type="text"
                    className="w-28 py-0 mt-1 border border-gray-400 rounded-md px-4"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Mode</label>
                  <form className="flex items-center">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative w-full">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" />
                      <select className="block appearance-none w-20 bg-white border border-gray-400 hover:border-gray-500 px-4 py-1 pr-10 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                        <option />
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </form>
                </div>
                <div>
                  <label className="block text-gray-700">Client Name</label>
                  <input
                    type="text"
                    className="w-full px-4 py-0 mt-1 border rounded-md border border-gray-400"
                  />
                </div>
                <div className="mt-1 gap-1 flex">
                  <div className="">
                    <input type="checkbox" className="form-checkbox" />
                    <span>Comm. Y/N</span>
                    <button className="bg-gray-200 px-4 py-0 border border-gray-400 ">
                      Save
                    </button>
                  </div>
                  <div className="mt-6">
                    <button className="bg-gray-200 px-4 py-0 border border-gray-400 ">
                      Delete
                    </button>
                  </div>
                </div>
                <div />
                <div className="mt-6">
                  <button className="bg-gray-200 px-4 py-0 rounded-md  text-gray-200">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionTransaction;
