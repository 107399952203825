import React from "react";
import NavBar from "../components/NavBar";

const Settlement = () => {
  return (
    <div>
      <NavBar />
      <>
        {/* Main Content */}
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
          {/* Left Sidebar */}
          <div className="col-span-1 lg:col-span-1 bg-gray-200 p-2 border border-gray-400">
            <div className=" p-1">
              <h3 className="text-lg font-bold">Party Trial</h3>
            </div>
            <div className="bg-gray-200 p-2  mb-2">
              <div className="text-center my-2 ">
                <button className="text-xl text-black bg-gray-300 px-4 py-1 rounded-md">
                  All Accounts Monday Final
                </button>
              </div>

              <div className="flex space-x-2 bg-gray-300 p-3">
                <form className="flex items-center">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"></div>
                    <select
                      className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-6 py-0 pr-8
           rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option />
                      <option>Option 2</option>
                      <option>Option 3</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="ml-2 text-black bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none
       focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-0 border border-gray-400"
                  >
                    Find
                  </button>
                  <button
                    type="submit"
                    className="ml-2 text-black bg-gray-200 hover:bg-gray-300 focus:ring-4 
      focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-0 border border-gray-400"
                  >
                    Clear
                  </button>
                </form>
              </div>
            </div>
            <table className="min-w-full border border-gray-300 mb-2">
              <thead className="bg-white border border-gray-500">
                <tr>
                  <th className="border px-4 py-2 text-left">AC Name</th>
                  <th className="border px-4 py-2 text-right">
                    Current Balance
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                <tr>
                  <td className="border px-4 py-2">ABHINAV</td>
                  <td className="border px-4 py-2 text-right">0.00</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">BHANU</td>
                  <td className="border px-4 py-2 text-right">-300.00</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">CASH</td>
                  <td className="border px-4 py-2 text-right">700.00</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">LOKESH JI</td>
                  <td className="border px-4 py-2 text-right text-red-600">
                    -9628.83
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">LOKESH JI</td>
                  <td className="border px-4 py-2 text-right text-red-600">
                    -9628.83
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">LOKESH JI</td>
                  <td className="border px-4 py-2 text-right text-red-600">
                    -9628.83
                  </td>
                </tr>
                {/* More rows as needed */}
              </tbody>
            </table>

            <div className="flex justify-end ">
              <div className="space-y-1 text-right">
              <input
                  className="border border-gray-400  border rounded
                   w-24 py-1 text-black leading-tight focus:outline-none focus:shadow-outline"
                  id=""
                  type="text"
                  placeholder=""
                />
              </div>
            </div>
            <div className="border border-gray-400 m-1 p-1">
              <div className="flex justify-end gap-1  m-1 rounded-md">
                <div className="text-black text-lg">(+)Total</div>
                <div className="text-left">
                <input
                  className="border border-gray-400  border rounded
                   w-36 py-1 text-black leading-tight focus:outline-none focus:shadow-outline"
                  id=""
                  type="text"
                  placeholder=""
                />
                </div>
              </div>

              <div className="flex justify-end gap-1  m-1 rounded-md ">
                <div className="text-black text-lg">(+)Total</div>
                <div className=" text-left ">
                <input
                  className="border border-gray-400  border rounded
                   w-36 py-1 text-black leading-tight focus:outline-none focus:shadow-outline"
                  id=""
                  type="text"
                  placeholder=""
                />
                </div>
              </div>
              <div className="flex px-3 gap-2 mt-4 ">
                <div className="border border-gray-400">
                  <button className="bg-gray-200 text-black px-2 py-1 rounded-md border-gray-400">
                    Print Net Result
                  </button>
                </div>
                <div className="border border-gray-400">
                  <button className="bg-gray-200 text-black px-2 py-1 rounded-md border-gray-400">
                    Print Net Result
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Main Table and Details */}
          <div className="col-span-1 lg:col-span-3   rounded-md ">
            <div className="flex justify-between mb-4 p-2 bg-gray-200 border border-gray-400">
              <div className="flex gap-2 ">
                <span className="block text-md font-semibold">Old Balance</span>
                <input
                  className="border border-gray-400  border rounded
                   w-25 py-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id=""
                  type="text"
                  placeholder="-7161.33"
                />
                {/*<span class="block text-red-600 text-2xl">-7161.33</span>*/}
              </div>
              <div className="flex-1 text-center">
                {/*<span class="block text-xl font-semibold">LOKESH JI</span>*/}
                <input
                  className="border border-gray-400  border rounded w-25 py-1 text-black leading-tight focus:outline-none focus:shadow-outline text-center"
                  id="text"
                  type="text"
                  placeholder="LOKESH JI"
                />
              </div>
              <div className="flex gap-2">
                {/*<span class="block text-lg font-semibold">Current Balance</span>*/}
                <label
                  className="block text-gray-700 text-md font-bold mb-2"
                  htmlFor="username"
                >
                  Current Balance
                </label>
                <input
                  className="border border-gray-400  border rounded w-25 py-1 text-red-500 leading-tight focus:outline-none focus:shadow-outline"
                  id=""
                  type="text"
                  placeholder="-9628.83"
                />
                {/*<span class="block text-red-600 text-2xl">-9628.83</span>*/}
              </div>
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-1 mb-4">
              <div className="col-span-2 border border-gray-400 w-[100%]">
                <table className="min-w-full  border border-gray-400">
                  <thead className="bg-gray-200 border border-gray-400">
                    <tr>
                      <th className="border px-4 py-2 text-left">Comment</th>
                      <th className="border px-4 py-2 text-right">Amount</th>
                      <th className="border px-4 py-2 text-left">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-4 py-2">0404</td>
                      <td className="border px-4 py-2 text-right text-red-600">
                        -639.45
                      </td>
                      <td className="border px-4 py-2">04/04/2022 04:48 PM</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">2203</td>
                      <td className="border px-4 py-2 text-right text-red-600">
                        -550.00
                      </td>
                      <td className="border px-4 py-2">22/03/2022 10:00 AM</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">MUM VS KOL</td>
                      <td className="border px-4 py-2 text-right text-red-600">
                        -8139.38
                      </td>
                      <td className="border px-4 py-2">11/03/2022 07:15 PM</td>
                    </tr>

                    <tr>
                      <td className="border px-4 py-2">MUM VS KOL</td>
                      <td className="border px-4 py-2 text-right text-red-600">
                        -8139.38
                      </td>
                      <td className="border px-4 py-2">11/03/2022 07:15 PM</td>
                    </tr>

                    <tr className="bg-gray-200">
                      <td className="border px-4 py-1"></td>
                      <td className="border px-4 py-1 text-right text-black">
                      <div className="flex justify-end ">
                          <div className="space-y-1 text-right">
                          <input
                  className="border border-gray-400  border rounded
                   w-20 py-1 text-black leading-tight focus:outline-none focus:shadow-outline"
                  id=""
                  type="text"
                  placeholder=""
                />
                          </div>
                        </div>
                      </td>
                      <td className="border px-4 py-2"></td>
                    </tr>


                    {/* More rows as needed */}
                  </tbody>
                </table>
              
              </div>
              <div className="col-span-1 flex gap-3  bg-gray-300 p-2 w-[100%] border border-gray-400">
                {/*<h4 class="text-md font-semibold">Account Actions</h4>*/}
                <div className="space-y-2">
                  <button className="w-full bg-gray-200 text-black px-2 py-1 rounded-md border border-gray-400 ">
                    Monday Final
                  </button>
                  <button className="w-full bg-gray-200 text-black px-2 py-1 rounded-md border border-gray-400 ">
                    Ledger
                  </button>
                  <button className="w-full bg-gray-200 text-black py-1 rounded-md border border-gray-400 ">
                    Last week records
                  </button>
                  <button className="w-full bg-gray-200 text-black px-2 py-1 rounded-md border border-gray-400 ">
                    Back
                  </button>
                  <div className="flex items-center mt-2">
                    <input type="checkbox" className="mr-2" />
                    <label className="block text-gray-700">
                      Hide 0.00 Balance A/c
                    </label>
                  </div>
                </div>

                <div className=" ">
                  <div className="justify-between items-center">
                    <span className="font-semibold">Balance Tallied</span>
                    <button className=" bg-gray-200 text-black px-4 py-1 rounded-md border border-gray-400">
                      Tallied
                    </button>
                  </div>
                  <div className="mt-2">
                    <label className="block text-gray-700">Last Date</label>
                    <input
                      type="date"
                      className="w-full px-2 py-1 border border-gray-400 rounded-md bg-gray-200"
                    />
                  </div>
                  <div className="mt-2">
                    <label className="block text-gray-700">Last Balance</label>
                    <input
                      type="text"
                      className="w-full px-2 py-1 border border-gray-400 rounded-md bg-gray-200"
                      defaultValue={0.0}
                    />
                  </div>
                  <div className="mt-2">
                    <label className="block text-gray-700">Last Amt Date</label>
                    <input
                      type="date"
                      className="w-full px-2 py-1 border border-gray-400 rounded-md bg-gray-200"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="col-span-1  border border-gray-400">
                <table className="w-full border border-gray-300">
                  <thead className="bg-gray-200">
                    <tr>
                      <th className="border px-4 py-2 text-left">Date</th>
                      <th className="border px-4 py-2 text-left">Name</th>
                      <th className="border px-4 py-2 text-left">Reference</th>
                      <th className="border px-4 py-2 text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-4 py-2">11/03/2022 07:23 PM</td>
                      <td className="border px-4 py-2">CASH</td>
                      <td className="border px-4 py-2">First Comm</td>
                      <td className="border px-4 py-2 text-right text-red-600">
                        -300.00
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-4 py-2">11/03/2022 07:23 PM</td>
                      <td className="border px-4 py-2">CASH</td>
                      <td className="border px-4 py-2">First Comm</td>
                      <td className="border px-4 py-2 text-right text-red-600">
                        -300.00
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">11/03/2022 07:23 PM</td>
                      <td className="border px-4 py-2">CASH</td>
                      <td className="border px-4 py-2">First Comm</td>
                      <td className="border px-4 py-2 text-right text-red-600">
                        -300.00
                      </td>
                    </tr>

                    <tr className="bg-gray-200">
                      <td className="border px-4 py-2"></td>
                      <td className="border px-4 py-2"></td>
                      <td className="border px-4 py-2"></td>
                      <td className="border px-4 py-2 text-right text-black">
                        <div className="flex justify-end ">
                        <input
                  className="border border-gray-400  border rounded
                   w-20 py-1 text-black leading-tight focus:outline-none focus:shadow-outline"
                  id=""
                  type="text"
                  placeholder=""
                />
                        </div>
                      </td>
                    </tr>

                    {/* More rows as needed */}
                  </tbody>
                </table>
              </div>

              <div className="col-span-1 border border-gray-400">
                <table className="w-full border border-gray-300">
                  <thead className="bg-gray-200">
                    <tr>
                      <th className="border px-4 py-2 text-left">Date</th>
                      <th className="border px-4 py-2 text-left">Name</th>
                      <th className="border px-4 py-2 text-left">Reference</th>
                      <th className="border px-4 py-2 text-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-4 py-2">11/03/2022 07:23 PM</td>
                      <td className="border px-4 py-2">CASH</td>
                      <td className="border px-4 py-2">First Comm</td>
                      <td className="border px-4 py-2 text-right text-red-600">
                        -300.00
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-4 py-2">11/03/2022 07:23 PM</td>
                      <td className="border px-4 py-2">CASH</td>
                      <td className="border px-4 py-2">First Comm</td>
                      <td className="border px-4 py-2 text-right text-red-600">
                        -300.00
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-4 py-2">11/03/2022 07:23 PM</td>
                      <td className="border px-4 py-2">CASH</td>
                      <td className="border px-4 py-2">First Comm</td>
                      <td className="border px-4 py-2 text-right text-red-600">
                        -300.00
                      </td>
                    </tr>
                    <tr className="bg-gray-200">
                      <td className="border px-4 py-2"></td>
                      <td className="border px-4 py-2"></td>
                      <td className="border px-4 py-2"></td>
                      <td className="border px-4 py-2 text-right text-black">
                        <div className="flex justify-end ">
                        <input
                  className="border border-gray-400  border rounded
                   w-20 py-1 text-black leading-tight focus:outline-none focus:shadow-outline"
                  id=""
                  type="text"
                  placeholder=""
                />
                        </div>
                      </td>
                    </tr>

                    {/* More rows as needed */}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="flex">
              <div className="flex justify-start mt-4 space-x-2 w-[50%]">
                <button className="bg-gray-200 text-black px-4 py-0 rounded-md border border-gray-400">
                  Delete
                </button>
                <button className="bg-gray-200 text-black px-4 py-0 rounded-md border border-gray-400">
                  Edit
                </button>
              </div>

              <div className="flex justify-end mt-4 space-x-2">
                <button className="bg-gray-200 text-black px-4 py-0 rounded-md border border-gray-400">
                  Delete
                </button>
                <button className="bg-gray-200 text-black px-4 py-0 rounded-md border border-gray-400">
                  Edit
                </button>
              </div>
            </div>
            <div className="mt-5 border border-gray-400">
              <div className="bg-gray-300 p-1">
                <h4 className="text-md font-bold">Account Posting</h4>
              </div>
              <div className="flex space-x-2 ml-auto  text-left bg-gray-200 p-1 border border-gray-300">
                <div>
                  <label className="block text-black">Balance</label>
                  <input
                    type="text"
                    className="w-20 py-1 mt-1 border border-gray-400  px-4"
                  />
                </div>

                <div>
                  <label className="block text-black mt-1">Client</label>
                  <form className="flex items-center">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative w-full">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" />
                      <select className="block appearance-none w-52 bg-white border border-gray-400 hover:border-gray-500 px-4 py-1 pr-10 ">
                        <option />
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </form>
                </div>

                <div>
                  <label className="block text-black font-normal">Amount</label>
                  <input
                    type="text"
                    className="w-32 py-1 mt-1 border border-gray-400  px-4"
                  />
                </div>

                <div>
                  <label className="block text-black font-normal">
                    Narration
                  </label>
                  <input
                    type="text"
                    className="w-full px-4 py-1 mt-1 border  border border-gray-400"
                  />
                </div>
                <div className="mt-1 gap-1 flex">
                  <div className="mt-6">
                    <button className="bg-gray-200 px-4 py-0 border border-gray-400 text-black rounded text-lg font-semibold ">
                      Save
                    </button>
                  </div>
                  <div className="mt-6">
                    <button className="bg-gray-200 px-4 py-0 border border-gray-400 text-black rounded text-lg text-lg font-semibold ">
                      Print
                    </button>
                  </div>

                  <div className="mt-6">
                    <button className="bg-gray-200 px-4 py-0 border border-gray-400 text-black rounded text-lg font-semibold ">
                      Exit
                    </button>
                  </div>
                </div>
                <div />
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Settlement;
