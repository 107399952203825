import React from 'react'
import { Link } from 'react-router-dom'
import NavBar from '../components/NavBar'
const TrialBalance = () => {
  return (
    <div>
        <NavBar/>
      <div className="container mx-auto bg-white p-6 rounded-lg shadow-lg">
  <div className="flex items-center mb-4 ">
    <h1 className="text-xl font-bold">Select Match/Session</h1>
    <div className="space-x-2">
      <button className="bg-gray-200 px-4 py-2 rounded-md ml-5">
      <Link
                to="/Clientwise"
                className="text-gray-800 hover:text-blue-600 text-sm"
              >
                 Clientwise
              </Link>
      </button>
      <button className="bg-blue-500 text-white px-4 py-2 rounded-md">
      <Link
                to="/Groupwise"
                className="text-gray-800 hover:text-blue-600  text-sm"
              >
                Groupwise
              </Link>
      </button>
      <button className="bg-gray-200 px-4 py-2 rounded-md">Print</button>
      <button className="bg-gray-200 px-4 py-2 rounded-md">Exit</button>
    </div>
  </div>
  <div className="flex gap-4">
    <div className="overflow-x-auto w-[35%]">
      <table className="min-w-full bg-white border rounded-lg">
        <thead className="divide-x">
          <tr className="bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <th className="p-2">..</th>
            <th className="p-2">Date</th>
            <th className="p-2">Name</th>
          </tr>
        </thead>
        <tbody className="divide-y divide divide-gray-200">
          {/* Add your data rows here */}
          <tr className="divide-x">
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <td className="p-2">04/04/2022</td>
            <td className="p-2">6 lkw</td>
          </tr>
          <tr className="divide-x">
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <td className="p-2">04/04/2022</td>
            <td className="p-2">0404</td>
          </tr>
          <tr className="divide-x">
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <td className="p-2">24/03/2022</td>
            <td className="p-2">6</td>
          </tr>
          <tr className="divide-x">
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <td className="p-2">22/03/2022</td>
            <td className="p-2">2203</td>
          </tr>
          <tr className="divide-x">
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <td className="p-2">11/03/2022</td>
            <td className="p-2">10 ovr</td>
          </tr>
          <tr className="divide-x">
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <td className="p-2">11/03/2022</td>
            <td className="p-2">MUM VS KOL</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="overflow-x-auto w-[33%] ">
      <h1 className="text-center text-3xl font-semibold">Receive</h1>
      <table className="min-w-full bg-white border rounded-lg">
        <thead className="divide-x">
          <tr className="bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <th className="p-2">Act</th>
            <th className="p-2">Name</th>
            <th className="p-2">Amount</th>
          </tr>
        </thead>
        <tbody className="divide-y divide divide-gray-200">
          {/* Add your data rows here */}
          <tr className="divide-x">
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <td className="p-2">CASH</td>
            <td className="p-2">100</td>
          </tr>
          <tr className="divide-x">
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <td className="p-2">FC</td>
            <td className="p-2">30000</td>
          </tr>
          <tr className="divide-x">
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <td className="p-2">NAVNEET</td>
            <td className="p-2">145</td>
          </tr>
          <tr className="divide-x">
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <td className="p-2">SANWARIYA</td>
            <td className="p-2">34115</td>
          </tr>
          <tr className="divide-x">
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <td className="p-2">SONU YC</td>
            <td className="p-2">100</td>
          </tr>
          <tr className="divide-x">
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <td className="p-2" />
            <td className="p-2" />
          </tr>
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-4">
        <div className="text-lg font-bold text-blue-500" />
        <div className="text-lg font-bold text-black-500">Final Balance:</div>
      </div>
    </div>
    <div className="overflow-x-auto w-[33%] ">
      <h1 className="text-center text-3xl font-semibold">Pay</h1>
      <table className="min-w-full bg-white border rounded-lg">
        <thead className="divide-x">
          <tr className="bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <th className="p-2">Act</th>
            <th className="p-2">Name</th>
            <th className="p-2">Amount</th>
          </tr>
        </thead>
        <tbody className="divide-y divide divide-gray-200">
          {/* Add your data rows here */}
          <tr className="divide-x">
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <td className="p-2">LOKESH JI</td>
            <td className="p-2">-9328.83</td>
          </tr>
          <tr className="divide-x">
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <td className="p-2">RECE</td>
            <td className="p-2">-320</td>
          </tr>
          <tr className="divide-x">
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <td className="p-2" />
            <td className="p-2" />
          </tr>
          <tr className="divide-x">
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <td className="p-2" />
            <td className="p-2" />
          </tr>
          <tr className="divide-x">
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <td className="p-2" />
            <td className="p-2" />
          </tr>
          <tr className="divide-x">
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <td className="p-2" />
            <td className="p-2" />
          </tr>
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-4">
        <div className="text-lg font-bold text-blue-700">$54,811.48</div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default TrialBalance
