import React from 'react'
import NavBar from '../components/NavBar'

const SystemUser = () => {
  return (
    <div>
        <NavBar/>
      <>
  {/* User Management Table */}
  <div className="overflow-x-auto m-3 h-full">
    <table className="min-w-full bg-white border border-gray-300 ">
      <thead className="bg-gray-200">
        <tr>
          <th className="text-left py-2 px-4 border-b">Display Name</th>
          <th className="text-left py-2 px-4 border-b">Username</th>
          <th className="text-left py-2 px-4 border-b">Password</th>
          <th className="text-left py-2 px-4 border-b">User Type</th>
        </tr>
      </thead>
      <tbody className="">
        <tr>
          <td className="py-2 px-4 border-b">Admin</td>
          <td className="py-2 px-4 border-b">admin</td>
          <td className="py-2 px-4 border-b">******</td>
          <td className="py-2 px-4 border-b">Admin</td>
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>

        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>

        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>

        <tr>
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
          <td className="py-2 px-4 " />
        </tr>
      </tbody>
    </table>
  </div>
  {/* Add/Edit, Save, Exit Buttons */}
  {/*---end*/}
  <div className="flex space-x-2 bg-gray-200 p-2  mb-3 m-3 ">
    <button className="bg-gray-200 px-4 py-2 rounded-md border border-black border border-gray-400">
      Add/Edit
    </button>
    <button className="bg-gray-200 px-4 py-2 rounded-md border border-black text-gray-400 border border-gray-400">
      Save
    </button>
    <button className="bg-gray-200 px-4 py-2 rounded-md border border-black border border-gray-400">
      Exit
    </button>
  </div>
</>

    </div>
  )
}

export default SystemUser
